import { Icon } from '@chakra-ui/react';

export const MapWizardIcon = (props) => <Icon viewBox='0 0 20 20' {...props}>
    <path d='M15.5892 6.91085L13.0892 4.41085C12.7633 4.08502 12.2367 4.08502 11.9108 4.41085L10 6.32168L13.6783 10L15.5892 8.08918C15.915 7.76335 15.915 7.23668 15.5892 6.91085Z' />
    <path d='M8.82167 7.5L1.0775 15.2442C0.751672 15.57 0.751672 16.0967 1.0775 16.4225L3.57751 18.9225C3.74001 19.085 3.95334 19.1667 4.16667 19.1667C4.38 19.1667 4.59334 19.085 4.75584 18.9225L12.5 11.1783L8.82167 7.5Z' />
    <path d='M13.3333 0H11.6667V2.5H13.3333V0Z' />
    <path d='M18.3921 2.7858L17.2136 1.6073L15.4459 3.37505L16.6244 4.55355L18.3921 2.7858Z' />
    <path d='M20 8.33332L20 6.66666L17.5 6.66666L17.5 8.33332L20 8.33332Z' />
    <path d='M17.2146 13.3926L18.3931 12.2141L16.6254 10.4463L15.4469 11.6248L17.2146 13.3926Z' />
    <path d='M7.78621 1.60745L6.60771 2.78595L8.37546 4.5537L9.55396 3.3752L7.78621 1.60745Z' />
</Icon>;

export const DocumentationIcon = (props) => <Icon viewBox='0 0 20 20' {...props}>
    <path d='M17.5 0H2.50001C2.27899 0 2.06703 0.0877974 1.91075 0.244078C1.75447 0.400358 1.66667 0.61232 1.66667 0.833333V19.1667C1.66667 19.3877 1.75447 19.5996 1.91075 19.7559C2.06703 19.9122 2.27899 20 2.50001 20H17.5C17.721 20 17.933 19.9122 18.0893 19.7559C18.2455 19.5996 18.3333 19.3877 18.3333 19.1667V0.833333C18.3333 0.61232 18.2455 0.400358 18.0893 0.244078C17.933 0.0877974 17.721 0 17.5 0ZM15 16.6667H5.00001V15H15V16.6667ZM15 12.5H5.00001V10.8333H15V12.5ZM15 8.33333H5.00001V3.33333H15V8.33333Z' />
</Icon>;

export const PlansPricingIcon = (props) => <Icon viewBox='0 0 20 20' {...props}>
    <path d='M7.08333 18.3334H2.08333C1.5308 18.3334 1.0009 18.1139 0.610194 17.7232C0.219493 17.3325 0 16.8026 0 16.25L0 3.75002C0 3.19749 0.219493 2.66758 0.610194 2.27688C1.0009 1.88618 1.5308 1.66669 2.08333 1.66669H7.08333C7.63587 1.66669 8.16577 1.88618 8.55647 2.27688C8.94717 2.66758 9.16667 3.19749 9.16667 3.75002V16.25C9.16667 16.8026 8.94717 17.3325 8.55647 17.7232C8.16577 18.1139 7.63587 18.3334 7.08333 18.3334Z' />
    <path d='M12.5 5.00002H10.8333V3.75002C10.8333 3.19749 11.0528 2.66758 11.4435 2.27688C11.8342 1.88618 12.3641 1.66669 12.9167 1.66669H14.1667V3.33335H12.9167C12.8062 3.33335 12.7002 3.37725 12.622 3.45539C12.5439 3.53353 12.5 3.63951 12.5 3.75002V5.00002Z' />
    <path d='M20 5.00002H18.3333V3.75002C18.3333 3.63951 18.2894 3.53353 18.2113 3.45539C18.1332 3.37725 18.0272 3.33335 17.9167 3.33335H16.6667V1.66669H17.9167C18.1903 1.66669 18.4612 1.72057 18.7139 1.82527C18.9667 1.92997 19.1964 2.08343 19.3898 2.27688C19.5833 2.47034 19.7367 2.7 19.8414 2.95276C19.9461 3.20552 20 3.47643 20 3.75002V5.00002Z' />
    <path d='M17.9167 18.3333H16.6667V16.6667H17.9167C18.0272 16.6667 18.1332 16.6228 18.2113 16.5446C18.2894 16.4665 18.3333 16.3605 18.3333 16.25V15H20V16.25C20 16.5236 19.9461 16.7945 19.8414 17.0473C19.7367 17.3 19.5833 17.5297 19.3898 17.7231C19.1964 17.9166 18.9667 18.0701 18.7139 18.1747C18.4612 18.2794 18.1903 18.3333 17.9167 18.3333Z' />
    <path d='M14.1667 18.3333H12.9167C12.3641 18.3333 11.8342 18.1138 11.4435 17.7231C11.0528 17.3324 10.8333 16.8025 10.8333 16.25V15H12.5V16.25C12.5 16.3605 12.5439 16.4665 12.622 16.5446C12.7002 16.6228 12.8062 16.6667 12.9167 16.6667H14.1667V18.3333Z' />
    <path d='M12.5 6.66669H10.8333V9.16669H12.5V6.66669Z' />
    <path d='M20 6.66669H18.3333V9.16669H20V6.66669Z' />
    <path d='M12.5 10.8334H10.8333V13.3334H12.5V10.8334Z' />
    <path d='M20 10.8334H18.3333V13.3334H20V10.8334Z' />
</Icon>;

export const OtherExamplesIcon = (props) => <Icon viewBox='0 0 64 64' {...props}>
    <path d="M2,59a1,1,0,0,0,.287-.042l19.666-5.9,11.676,4.67a1,1,0,0,0,.742-1.858L23,51.323V5.477l18,7.2V31a1,1,0,0,0,2,0V12.744l18-5.4V35a1,1,0,0,0,2,0V6a1,1,0,0,0-1.287-.958l-19.666,5.9L22.371,3.071a1.01,1.01,0,0,0-.658-.029l-20,6A1,1,0,0,0,1,10V58a1,1,0,0,0,1,1ZM3,10.744l18-5.4V51.256l-18,5.4Z" fill="#ffffff">
    </path>
    <path d="M50,37A12,12,0,1,0,62,49,12,12,0,0,0,50,37Zm5,13H51v4a1,1,0,0,1-2,0V50H45a1,1,0,0,1,0-2h4V44a1,1,0,0,1,2,0v4h4a1,1,0,0,1,0,2Z"></path>
</Icon>;
