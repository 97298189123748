import { DemoColors } from "../../constants/colors";
import { ThemeId } from "../../constants/sidebar";
import { Theme } from "../../types/sidebar";

const getDegreeDaysColor = (type: string, degrees: number) => {
    let color = '';
    let value = degrees < 0 ? degrees * -1 : degrees;
    
    if (value === 0 || (type === 'cooling' && degrees <= 0) || (type === 'heating' && degrees >= 0)) {
        color = DemoColors.energy.degreeDays.zero;
    } else if (value > 0 && value < 10) {
        color = DemoColors.energy.degreeDays[type][0];
    } else if (value >= 10 && value < 20) {
        color = DemoColors.energy.degreeDays[type][1];
    } else if (value >= 20 && value < 30) {
        color = DemoColors.energy.degreeDays[type][2];
    } else if (value >= 30 && value < 40) {
        color = DemoColors.energy.degreeDays[type][3];
    } else if (value >= 40 && value < 50) {
        color = DemoColors.energy.degreeDays[type][4];
    } else {
        color = DemoColors.energy.degreeDays[type][5];
    }

    return color;
}

export const DegreeDays = ({ forecastsPeriods, theme }) => {
    const degreeDays: any = [];
    let coolingDegreesTotal: number = 0;
    let heatingDegreesTotal: number = 0;

    const datesMarkup = ({ month, day }: { month: string, day: number }) => `
                                    <div class="degree-days__dates-cell">
                                        <span class='degree-days__dates-month'>${month}</span>
                                        <span degree-days__dates-day>${day}</span>
                                    </div>`
    const degreeDaysMarkup = (type: string, degrees: number, theme: Theme) => {
        let value: string;
        if (type === 'cooling' && degrees > 0) {
            value = `${degrees}`;
        } else if (type === 'heating' && degrees < 0) {
            value = `${degrees * -1}`;
        } else {
            value = '-';
        }
        return (`<div class="degree-days__cell">
                    <span class='degree-days__rect' style="background-color: ${getDegreeDaysColor(type, degrees)}"></span>
                    <span class="degree-days__temp degree-days__temp--${theme === ThemeId.dark ? 'light' : 'dark'}">${value}</span>
                </div>`)
    }

    if (forecastsPeriods) {
        forecastsPeriods.forEach((period) => {
            const { minTempF, maxTempF, dateTimeISO }: { minTempF: number, maxTempF: number, dateTimeISO: string } = period;
            const meanTempF: number = (minTempF + maxTempF) / 2;
            const date = new Date(dateTimeISO);
            let day: number = date.getDate();
            let month: string = date.toLocaleDateString('en-us', { month: "short" })
            const degrees: number = meanTempF - 65;

            if (degrees > 0) {
                coolingDegreesTotal += degrees;
            } else if (degrees < 0) {
                heatingDegreesTotal += (degrees * -1);
            }

            degreeDays.push({ degrees: meanTempF - 65, month: degreeDays.every(x => x.month !== month) ? month : '', day });
        });
    }
    return ` 
            <div class="awxjs__app__ui-panel-info__view-title mb-3">Degree Days</div>
            <div class='degree-days__row degree-days__dates'>
                <div class="degree-days__dates-cell degree-days__dates-cell--${theme === ThemeId.dark ? 'light' : 'dark'} mr-2"></div>
                ${degreeDays?.map((degreeDay) => datesMarkup(degreeDay)).join('')}
            </div>
            <div class="degree-days__row degree-days__dd-title">
                <div class="degree-days__cell mr-2">CDD</div>
                ${degreeDays?.map((degreeDay) => degreeDaysMarkup('cooling', degreeDay.degrees, theme)).join('')}
            </div>
            <div class="degree-days__row degree-days__dd-title">
                <div class="degree-days__cell mr-2">HDD</div>
                ${degreeDays?.map((degreeDay) => degreeDaysMarkup('heating', degreeDay.degrees, theme)).join('')}
            </div>
            <div class="degree-days__row degree-days__total">
                <span class="font-weight-bold">Total</span>
                <div class="degree-days__totals-container">
                        <span class="font-weight-light font-size-normal mr-1">CDD</span>
                        <span class="font-weight-bold font-size-large mr-3">${coolingDegreesTotal}</span>
                        <span class="font-weight-light font-size-normal mr-1">HDD</span>
                        <span class="font-weight-bold font-size-large">${heatingDegreesTotal}</span>
                </div>
            </div>
            <div class="degree-days__legend degree-days__legend--${theme === ThemeId.dark ? 'light' : 'dark'}">
                <span>CDD = Cooling Degree Days</span>
                <span>HDD = Heating Degree Days</span>
            </div>
        `
}
