import keys from '../../constants/keys';
import { isEmpty } from '../../utils/object';
import { Demo } from '../../types/sidebar';
import { capitalizeWords } from '../../utils/text';
import { WeatherphraseUrls } from '../../constants/urls';

type DemoWithUseCase = 'routing' | 'maritime';
type UseCase = 'general' | 'trucking' | 'maritime_shipping' | 'maritime_small_craft';
type UseCaseId = {
    general: UseCase,
    trucking: UseCase,
    maritimeShipping: UseCase
    maritimeSmallCraft: UseCase
}

const useCaseId: UseCaseId = {
    general: 'general',
    trucking: 'trucking',
    maritimeShipping: 'maritime_shipping',
    maritimeSmallCraft: 'maritime_small_craft'
}

interface DemoUseCaseData {
    initialUseCase: UseCase,
    availableOptions?: UseCase[]
}

const useCaseOptionsData = [{
    key: useCaseId.maritimeSmallCraft,
    name: 'Maritime Small Craft'
},
{
    key: useCaseId.maritimeShipping,
    name: 'Maritime Shipping'
},
{
    key: useCaseId.trucking,
    name: 'Trucking'
}];

const demoUseCaseMapping: Record<DemoWithUseCase, DemoUseCaseData> = {
    maritime: {
        initialUseCase: 'maritime_shipping',
        availableOptions: ['maritime_shipping', 'maritime_small_craft']
    },
    routing: {
        initialUseCase: 'trucking',
    }
}

interface ImpactsProps {
    demo?: Demo,
    lastCoord: {
        lat: number,
        lon: number
    },
    initialMinImpactIndexLevel?: number
}

let useCase: UseCase;
let minImpactIndexLevel: number;
let activeId: string = '';

// Hide the loader
function hideLoader() {
    const loader = document?.getElementById('loader');
    if (loader) {
        loader.style.display = 'none'
    }
}

function generateUniqueId() {
    return 'xxxx-xxxx-xxx-xxxx'.replace(/x/g, () => {
        const r = Math.floor(Math.random() * 16);
        return r.toString(16);
    });
}

const getBlankImpactsPanel = (useId) => {
    return `<div id="impacts_panel-${useId}-ai" class="impacts_panel-ai"><div class="loader" id="loader" style="display:block;"></div></div>
    <div id="impacts_panel-${useId}-sliders" class="impacts_panel-sliders"></div>`;
};

// Send a message to our streaming endpoint
const sendMessage = async (params, messageListener) => {
    const location = params.p || '';
    delete params.p
    const url = `${WeatherphraseUrls.impactsStream}${location}?${new URLSearchParams(params).toString()}`;

    try {

        const response = await fetch(url);

        if (!response.ok) {
            return false;
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder(); // Create a TextDecoder instance
        let done, value;

        while (!done) {
            if (reader) {
                ({ value, done } = await reader.read());
                const text = decoder.decode(value); // Decode the Uint8Array to a string
                if (messageListener(text, done) === false) {
                    await reader.cancel();
                    break;
                }
            }
        }

        return true;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        return false;
    }
};

async function fetchImpacts(location: string, useCase: string, min_index: number = 0) {
    const { id, secret } = keys.aeris;
    const impactsUrl = `${WeatherphraseUrls.impacts}${location}?use_case=${useCase}&min_index=${min_index}&client_id=${id}&client_secret=${secret}&`;
    const response = await fetch(impactsUrl);
    return await response.json();
}

const fetchAISummary = (location: string, currentId: string, currentUseCase: string) => {
    sendMessage({
        p: location,
        client_id: keys.aeris.id,
        client_secret: keys.aeris.secret,
        use_case: currentUseCase,
        min_index: minImpactIndexLevel
    }, (message) => {
        if (activeId !== currentId) {
            return false;
        }
        const panel = document.getElementById(`impacts_panel-${currentId}-ai`)
        if (panel) {
            hideLoader();
            panel.innerHTML += message;
        } else {
            return false;
        }
    })
};

const handleUseCaseChange = (e, location: string) => {
    useCase = e.target.value;
    // updateParams();

    const newId = generateUniqueId();
    activeId = newId;
    const impactPanelData = document.getElementById(`impacts_panel-data`);
    if (impactPanelData) {
        impactPanelData.innerHTML = getBlankImpactsPanel(newId);
    }
    updateImpacts(location, newId);
};

const Impact = (impact) => {
    const index = impact.index;
    const percent = Math.round((index / 5) * 1000) / 10;
    const indexStr = `${index}`.replace(/\./g, 'p');
    const category = capitalizeWords(impact.category);
    const name = capitalizeWords(impact.name);
    return (`
            <div class="awxjs__app__ui-panel-info__hazard awxjs__ui-cols align-center">
                <div class="awxjs__app__ui-panel-info__hazard-label">${name}</div>
                <div class="awxjs__app__ui-panel-info__hazard-bar">
                    <div class="awxjs__app__ui-panel-info__hazard-bar-inner">
                        <div class="awxjs__app__ui-panel-info__hazard-bar-progress awxjs__app__ui-panel-info__hazard-indice-fill-${indexStr}" style="width:${percent}%;"></div>
                    </div>
                </div>
                <div class="awxjs__app__ui-panel-info__hazard-value awxjs__app__ui-panel-info__hazard-value-${indexStr}">${category}</div>
            </div>
        `);
};

const updateImpacts = (location: string, currentId: string) => {
    const impactsData = fetchImpacts(location, useCase).then((impactsData) => {

        if (isEmpty(impactsData) || !impactsData.hasOwnProperty('impacts')) {
            return '';
        }

        const impacts = impactsData.impacts.map((impact) => Impact(impact));
        const panel = document.getElementById(`impacts_panel-${currentId}-sliders`);
        if (panel) {
            panel.innerHTML = `<div class="awxjs__app__ui-panel-info__hazards">${impacts.join('')}</div>`;
            const impactsUseCase = document.getElementById(`impacts-use-case`);
            if (impactsUseCase) {
                impactsUseCase.onchange = (e) => {
                    handleUseCaseChange(e, location);
                }
            }
            if (useCase.startsWith('maritime') && impacts.length === 0) {
                const aiPanel = document.getElementById(`impacts_panel-${currentId}-ai`);
                if (aiPanel) {
                    aiPanel.innerHTML = "<strong>Maritime impacts are not available over land.</strong>";
                }
            } else {
                fetchAISummary(location, currentId, useCase);
            }
            const infoPanel: HTMLElement | null = document.querySelector('.awxjs__app__ui-panel-info');
            if (infoPanel) {
                const currentMaxHeightPixelString = infoPanel.style.maxHeight;
                if (currentMaxHeightPixelString) {
                    const numberOfPixels = Number(currentMaxHeightPixelString.substring(0, currentMaxHeightPixelString.length - 2));
                    if (numberOfPixels < 600) {
                        infoPanel.style.maxHeight = '600px';
                    }
                }
            }
        }
    }
    ).catch(error => {
        console.error(error);
    });
}

const Option = (option: UseCase, selected: UseCase) =>
    `<option value=${option} ${selected === option ? 'selected' : ''}>
        ${useCaseOptionsData.find(data => data.key === option)?.name}
    </option>`

export const Impacts = ({ demo, lastCoord, initialMinImpactIndexLevel = 2 }: ImpactsProps) => {
    const currentId = generateUniqueId();
    useCase = demo === undefined ? 'general' : demoUseCaseMapping[demo].initialUseCase;
    minImpactIndexLevel = initialMinImpactIndexLevel;
    activeId = currentId;
    updateImpacts(`${lastCoord.lat},${lastCoord.lon}`, currentId)


    return `<div id="impacts-panel">
                <div class="awxjs__app__ui-panel-info__view-title">Impacts</div>
                <div class="awxjs__app__ui-panel-info__obs-timestamp">AI Assisted Impacts (Beta)</div>
                ${demo && demoUseCaseMapping[demo].availableOptions ?
            `<div class="impacts_panel-selectors row">
                <div class="col-sm">
                    <div class="input-group input-group-sm mb-3">
                            <label class="input-group-text" for="use_case">Use Case:</label>
                            <select class="form-select" id="impacts-use-case" name="impacts-use-case">
                            ${demoUseCaseMapping[demo].availableOptions.map((option) => Option(option, useCase))}
                            </select>
                    </div>
                </div>
            </div>` : ''} 
                
                <div id="impacts_panel-data">${getBlankImpactsPanel(currentId)}</div>
            </div>`;
};
