import { useState, useEffect } from 'react';
import { Button, useClipboard } from '@chakra-ui/react';
import { ShareIcon } from '../../assets/icons/sidebar/button';

const CopyUrlButton = () => {
    const { onCopy, hasCopied, value, setValue } = useClipboard('');

    const handleCopy = () => {
        setValue(window.location.href)
    };

    useEffect(() => {
        if (value) {
            onCopy();
        }
    }, [value]);

    return (
        <Button
            onClick={handleCopy}
            border='1px'
            borderColor='gray.300'
            bg='gray.300'
            _hover={{
                borderColor: 'gray.500'
            }}
            color='white'
            leftIcon={<ShareIcon boxSize='1rem' fill='white' />}
            fontWeight='normal'
            borderRadius='sm'
            size='sm'
            fontSize='13px'
            w='50%'
            justifyContent="flex-start">
            {hasCopied ? 'Copied!' : 'Copy URL'}
        </Button>
    );
};

export default CopyUrlButton;

