import React from 'react';
import { Box, BoxProps, useColorModeValue, chakra } from '@chakra-ui/react';
import { Nullable } from '../../types';
import { motion } from 'framer-motion';

export const StyledIconSizes = {
    base: 32,
    md: 40
};

type StyledIconProps = BoxProps & {
    iconSlot?: Nullable<React.ReactElement>;
};

const RingBox = chakra(motion.div);

const DYNAMIC_EASE = [0.83, 0, 0.17, 1];

const Ring = ({ inset = 0, ...props }: BoxProps & { inset: number; }) => (
    <RingBox
        position="absolute"
        top={`${inset}px`}
        left={`${inset}px`}
        right={`${inset}px`}
        bottom={`${inset}px`}
        borderWidth="1px"
        borderColor='brand.main'
        rounded="full"
        initial={{
            transform: 'scale(0.7)',
        }}
        animate={{
            transform: 'scale(1)',
        }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
            type: 'tween',
            ease: DYNAMIC_EASE,
            duration: 0.3
        }}
        exit={{
            transform: 'scale(0.7)',
        }}
        {...props}
    />
);

const ActiveDemoIcon = ({ children }: StyledIconProps) => (
    <motion.div
        initial={{
            opacity: 0,
        }}
        animate={{
            opacity: 1,
        }}
        transition={{
            type: 'tween',
            ease: DYNAMIC_EASE,
            duration: 0.3
        }}
        exit={{
            opacity: 0,
        }}
    >
        <Box
            position="relative"
            display="flex"
            alignItems='center'
            justifyContent='center'
            w='28px'
            h='28px'
            bg='brand.main'
            boxShadow={useColorModeValue('0 0 30px 10px hsla(200, 100%, 58%, 0.2)', '0 0 30px 10px hsla(200, 100%, 58%, 0.3)')}
            rounded="full"
        >
            <Ring inset={-4} />
            <Ring inset={-10} opacity={0.2} />
            {children}
        </Box>
    </motion.div>
);

export default ActiveDemoIcon;