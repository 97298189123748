import { extendTheme } from '@chakra-ui/react';

export const SidebarTheme = extendTheme({
    breakpoints: {
        sm: '30em', // 480px
        md: '48em', // 768px
        lg: '62em', // 992px
        xl: '80em', // 1280px
        xxl: '96em' // 1536px
    },
    colors: {
        brand: {
            main: '#0BA5EC'
        },
        gray: {
            100: '#14181A',
            200: '#454A4D',
            300: '#272C2E',
            400: '#464B4D',
            500: '#676f73'
        }
    }
});
