export const BasePath = `/${window.location.hostname === 'localhost' ? '' : `data-maps/${process.env.APP_VERSION}/`}`;
export const DataPath = `${BasePath}data/`;
export const AssetsPath = `${BasePath}assets/`;

export const OtherExamplesUrl = `/${window.location.hostname === 'localhost' ? '' : `data-maps/examples/`}`;

export const MapStylesByStrategyUrls = {
    mapbox: {
        style: {
            default: 'mapbox://styles/mapbox/streets-v12',
            light: 'mapbox://styles/mapbox/light-v11',
            dark: 'mapbox://styles/mapbox/dark-v11',
            satellite: 'mapbox://styles/mapbox/satellite-v9'
        }
    },
    maplibre: {
        style: {
            default: 'https://demotiles.maplibre.org/style.json'
        }
    },
    leaflet: {
        style: {
            default: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
            dark: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
        }
    }
};

export const AerisWeatherUrl = 'https://www.aerisweather.com/';

export const XWeatherUrl = 'https://www.xweather.com/';

export const AuthModalUrls = {
    terms: `${XWeatherUrl}terms/`,
    privacy: `${XWeatherUrl}privacy/`
};

export const SidebarUrls = {
    pricing: `${AerisWeatherUrl}pricing/`,
    mapWizard: `${AerisWeatherUrl}wizard/product/maps/type`,
    documentation: `${AerisWeatherUrl}support/docs/`,
    otherExamples: `${OtherExamplesUrl}?allow=1`
};

export const WbloxIconsUrl = 'https://cdn.aerisapi.com/wxblox/icons/';

export const WeatherphraseUrls = {
    impactsStream: 'https://api.weatherphrase.com/stream/impacts/',
    impacts: 'https://api-impacts.weatherphrase.com/impacts/'
};

export const AviationProxyUrl = `${OtherExamplesUrl}proxy/`;
