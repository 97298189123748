export const DemoColors = {
    energy: {
        region: {
            MRO: '#FFCF80',
            NPCC: '#FFFFB3',
            RFC: '#8A9B75',
            SERC: '#EDBFBF',
            SPP: '#70A7B0',
            TRE: '#B0C700',
            WECC: '#6C5078',
            Indeterminate: '#878787'
        },
        powerPlant: {
            'WIND ELECTRIC POWER GENERATION': "#9EC6E5",
            'SOLAR ELECTRIC POWER GENERATION': "#F78F1E",
            'HYDROELECTRIC POWER GENERATION': "#00A0B0",
            'NUCLEAR ELECTRIC POWER GENERATION': "#B2BEB5",
            'GEOTHERMAL ELECTRIC POWER GENERATION': "#716ACB",
            'FOSSIL FUEL ELECTRIC POWER GENERATION': "#323232",
            'BIOMASS ELECTRIC POWER GENERATION': "#8EBEA1"
        },
        degreeDays: {
            cooling: {
                0: '#A3E9FF',
                1: '#00C2FF',
                2: '#0F81F6',
                3: '#1E40ED',
                4: '#2E00E4',
                5: '#1D008E'
            },
            heating: {
                0: '#F5F0B0',
                1: '#F4E700',
                2: '#F49A00',
                3: '#F44D00',
                4: '#F40000',
                5: '#9A0000'
            },
            zero: '#EEEEEE'
        }
    },
    aviation: {
        flightrule: {
            VFR: '#1bbe08',
            MVFR: '#1c56cf',
            IFR: '#fa0708',
            LIFR: '#fb06ff'
        },
        status: {
            en_route: '#1bbe08'
        },
        sigmet: {
            TURB: '#e98e00',
            ICE: '#2452e9',
            CONVECTIVE: '#e90000',
            TS: '#ffe300'
        }
    }
};
