export const DemoId = {
    facilities: 'facilities',
    energy: 'energy',
    aviation: 'aviation',
    routing: 'routing',
    telecommunications: 'telecommunications',
    severe: 'severe',
    maritime: 'maritime'
} as const;


export const ThemeId = {
    default: 'default',
    light: 'light',
    dark: 'dark',
    satellite: 'satellite'
} as const;

export const StrategyId = {
    mapbox: 'mapbox',
    google: 'google',
    leaflet: 'leaflet',
    maplibre: 'maplibre'
} as const;


export const WeatherLayersId = {
    mapsGL: 'mapsGL',
    raster: 'raster'
} as const;
