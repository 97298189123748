import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './components/App';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        aeris: any;
    }
}

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(
    <Auth0Provider
        domain={process.env.AUTH0_DOMAIN!}
        clientId={process.env.AUTH0_CLIENT_ID!}
        authorizationParams={{
            redirect_uri: `${window.location.origin}${window.location.pathname}`,
            // audience: 'aerisweather-web-api-production',
            scope: 'openid profile email offline_access'
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
        cacheLocation='localstorage'
    >
        <App />
    </Auth0Provider>
);
