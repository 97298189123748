import { StrategyId } from "../constants/sidebar";
import keys from '../constants/keys';

export const BaseConfig = () => ({
    app: {
        mode: 'light',
        map: {
            strategy: StrategyId.mapbox,
            accessToken: keys.mapbox,
            map: {
                zoomControl: false
            },
            zoom: 3
        },
        panels: {
            timeline: {
                toggleable: true,
            }
        }
    }
});
