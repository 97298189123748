import { initializeLayersPanel } from '../utils/layers';
import { DemoId, WeatherLayersId } from '../constants/sidebar';
import {
    alertsButton,
    radarButton,
    satelliteButton,
    stormCellsSegments,
    currentsButton,
    sstButton,
    wavesSegments,
    swell1Segments,
    swell2Segments,
    swell3Segments,
    stormSurgeButton,
    tideHeightsButton
} from './common/buttons';
import {
    lightningStyle,
    defaultOverrides,
    futureOverrides,
    lightningInfo,
    FtoCUnits,
    CtoFUnits,
    alertsButtonGL,
    radarButtonGL,
    currentsButtonGL,
    sstButtonGL,
    wavesSegmentsGL,
    swell1SegmentsGL,
    swell2SegmentsGL,
    swell3SegmentsGL,
    stormSurgeButtonGL,
    tideHeightsButtonGL
} from './common/mapsGL';
import { Impacts } from './common/impacts';
import { baseMap } from './common/common';
import { DataSources } from '../constants/dataSources';
// import { addLightningThreats } from './common/sources/addLightningThreats';

let _aeris;
let lastCoord;

const maritime = DataSources.maritime;

const rasterLayers = [{
    title: 'Maritime',
    buttons: [
        sstButton,
        currentsButton,
        wavesSegments,
        swell1Segments,
        swell2Segments,
        swell3Segments,
        stormSurgeButton,
        tideHeightsButton
    ]
}, {
    title: 'Conditions',
    buttons: [
        radarButton
    ]
}, {
    title: 'Severe',
    buttons: [
        alertsButton,
        {
            id: 'stormreports',
            title: 'Storm Reports',
            value: 'stormreports'
        },
        stormCellsSegments,
        {
            title: 'Lightning Strikes',
            value: 'lightning-strikes'
        }]
}];

const mapsGLConfig = {
    layers: [{
        title: 'Maritime',
        buttons: [currentsButtonGL,
            sstButtonGL,
            wavesSegmentsGL,
            swell1SegmentsGL,
            swell2SegmentsGL,
            swell3SegmentsGL,
            stormSurgeButtonGL,
            tideHeightsButtonGL
        ]
    }, {
        title: 'Conditions',
        buttons: [
            radarButtonGL,
            {
                title: 'Winds',
                value: 'wind',
                options: {
                    ...defaultOverrides,
                    paint: {
                        sample: {
                            colorscale: {
                                normalized: true,
                                stops: [
                                    0, '#fff',
                                    1, '#fff'
                                ]
                            }
                        },
                        particle: {
                            size: 1,
                            count: Math.pow(128, 2)
                        }
                    }
                },
                segments: [{
                    title: 'Particles', value: 'wind-particles'
                }, { title: 'Barbs', value: 'wind-barbs' }]
            }]
    }, {
        title: 'Severe',
        buttons: [
            alertsButtonGL,
            {
                id: 'stormreports',
                title: 'Storm Reports',
                value: 'stormreports'
            }, {
                id: 'stormcells',
                title: 'Storm Cells',
                value: 'stormcells'
            }, {
                title: 'Lightning Strikes',
                value: 'lightning-strikes'
            }]
    }]
};

export const MaritimeConfig = ({ weatherLayers, layers, theme, sidebarControls }) => ({
    app: {
        ...baseMap,
        mode: 'light',
        panels: {
            layers: {
                toggleable: true,
                buttons: [
                    {
                        title: 'Gulf of Mexico Waterways',
                        value: 'waterways'
                    },
                    {
                        title: 'Ports',
                        value: 'ports'
                    },
                    {
                        title: 'Satellite',
                        buttons: [
                            satelliteButton
                        ]
                    },
                    {
                        id: 'tropical-cyclones',
                        title: 'Tropical System (Test)',
                        value: 'tropicaltest',
                        options: {
                            data: {
                                request: {
                                    endpoint: 'tropicalcyclones',
                                    parameters: {
                                        filter: 'test,geo'
                                    }
                                }
                            }
                        }
                    }, {
                        id: 'coastalalertstest',
                        value: 'tropical-cyclones-break-points',
                        title: 'Coastal Alerts (Test)',
                        options: {
                            data: {
                                request: {
                                    parameters: {
                                        filter: 'geo,test'
                                    }
                                }
                            }
                        }
                    },
                    ...(weatherLayers === 'raster' ? rasterLayers : [])
                ]
            },
            info: {
                views: {
                    localweather: {
                        request: (data) => {
                            const request = _aeris.api();
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            return request;
                        },
                        views: [{
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ demo: DemoId.maritime, lastCoord });
                            }
                        }]
                    },
                    port: {
                        request: (data) => {
                            const request = _aeris.api();
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            return request;
                        },
                        views: [{
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ demo: DemoId.maritime, lastCoord });
                            }
                        }]
                    }
                }
            },
            timeline: {
                toggleable: true,
                range: {
                    value: [-4, 0],
                    range: {
                        min: -48,
                        max: 24
                    },
                    marksEvery: '% 12',
                    step: 4
                }
            }
        }
    },
    events: {
        ready: (aeris, app) => {
            _aeris = aeris;

            app.map.on('click', (e) => {
                lastCoord = e.data.coord;
                app.showInfoAtCoord(e.data.coord, 'localweather', 'Local Weather');
            });

            app.addSource('ports', 'geojson', {
                data: {
                    url: (params) => maritime.ports.url,
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    marker: () => {
                        const icon = maritime.ports.icon;
                        return {
                            className: 'port',
                            icon: {
                                url: icon.url
                            },
                            size: [24, 24]
                        };
                    }
                }
            });

            app.map.on('marker:click', (e) => {
                if (e.data) {
                    lastCoord = e.data.marker.coordinate;
                    const data = e.data.data || {};
                    const source = data.awxjs_source;

                    if (source === 'ports') {
                        const loc = `${data.LATITUDE1},${data.LONGITUDE1}`;
                        const name = _aeris.utils.strings.toName(data.PORT_NAME);
                        app.showInfo('port', name).load({
                            p: loc
                        });
                    }
                }
            });

            app.addSource('waterways', 'geojson', {
                data: {
                    url: () => DataSources.maritime.waterways,
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    polyline: {
                        stroke: {
                            color: theme === 'dark' || theme === 'satellite' ? '#b0adc7' : '#727080',
                            width: 2
                        }
                    }
                }
            });

            aeris.modules().then((modules) => {
                app.modules.insertAt(0, modules.Routing, null, {
                    id: 'sea-ocean-routes',
                    title: 'Sea / Ocean Routes',
                    url(value) {
                        return `${DataSources.maritime.routes}${value}.json`;
                    },
                    onRouteData(data) {
                        sidebarControls.onSidebarClose();
                        return data;
                    },
                    routes: [{
                        id: 'route-1-maritime',
                        title: 'Route 1'
                    }, {
                        id: 'route-2-maritime',
                        title: 'Route 2'
                    }, {
                        id: 'route-3-maritime',
                        title: 'Route 3'
                    }, {
                        id: 'route-4-maritime',
                        title: 'Route 4'
                    }, {
                        id: 'route-5-maritime',
                        title: 'Route 5'
                    }, {
                        id: 'route-6-maritime',
                        title: 'Route 6'
                    }, {
                        id: 'route-7-maritime',
                        title: 'Route 7'
                    }, {
                        id: 'route-8-maritime',
                        title: 'Route 8'
                    }]
                });

                app.modules.insertAt(4, modules.groups.Tropical);

                setTimeout(() => {
                    initializeLayersPanel(
                        aeris,
                        app,
                        {
                            demo: DemoId.maritime,
                            weatherLayers
                        },
                        {
                            mapsGLConfig,
                            layersFromParams: layers
                        }
                    ).then(({ controller }) => {
                        app.on('layer:deselect', (e) => {
                            const { id } = e.data;

                            if (id === 'stormcells' && weatherLayers === WeatherLayersId.mapsGL) {
                                controller.removeWeatherLayer('stormcells');
                            }
                        });
                    });
                }, 250);
            });
        }
    }
});
