import { DataPath, AssetsPath } from "./urls";

export const DataSources = {
    outdoor: {
        stadiums: {
            sources: {
                baseball: {
                    url: `${DataPath}outdoor/ballparks.json`,
                    icon: {
                        url: `${AssetsPath}outdoor/baseball.svg`
                    }
                },
                football: {
                    url: `${DataPath}outdoor/football.json`,
                    icon: {
                        url: `${AssetsPath}outdoor/football.svg`
                    }
                },
                soccer: {
                    url: `${DataPath}outdoor/soccer.json`,
                    icon: {
                        url: `${AssetsPath}outdoor/soccer-ball.svg`
                    }
                }
            }
        },
        parks: {
            sources: {
                icon: {
                    url: `${AssetsPath}outdoor/land.svg`
                }
            }
        },
        schools: {
            sources: {
                icon: {
                    url: `${AssetsPath}outdoor/school.svg`
                }
            }
        }
    },
    aviation: {
        flights: {
            sources: {
                AAL: {
                    url: `${DataPath}aviation/airline-aal.json` 
                },
                ASA: {
                    url: `${DataPath}aviation/airline-asa.json` 
                },
                DAL: {
                    url: `${DataPath}aviation/airline-dal.json` 
                },
                FDX: {
                    url: `${DataPath}aviation/airline-fdx.json` 
                },
                FFT: {
                    url: `${DataPath}aviation/airline-fft.json` 
                },
                JBU: {
                    url: `${DataPath}aviation/airline-jbu.json` 
                },
                SWA: {
                    url: `${DataPath}aviation/airline-swa.json` 
                },
                UAL: {
                    url: `${DataPath}aviation/airline-ual.json` 
                },
                UPS:{ 
                    url: `${DataPath}aviation/airline-ups.json`
                }   
            },
            icon: {
                url: `${AssetsPath}aviation/flights.svg`
            }
        }
    },
    facilities: {
        facilities: {
            sources: {
                retail: {
                    url: `${DataPath}facilities/retail.json`,
                    icon: {
                        url: `${AssetsPath}facilities/store.svg`
                    }
                },
                distributionCenters: {
                    url: `${DataPath}facilities/distributionCenters.json`,
                    icon: {
                        url: `${AssetsPath}facilities/distributionCenter.svg`
                    }
                },
                dataCenters: {
                    url: `${DataPath}facilities/ballparks.json`,
                    icon: {
                        url: `${AssetsPath}telecommunications/server.svg`
                    }
                }
            }
        },
        assets: {
            sources: {
                home: {
                    url: `${DataPath}facilities/home.json`,
                    icon: {
                        url: `${AssetsPath}facilities/home.svg`
                    }
                },
                auto: {
                    url: `${DataPath}facilities/auto.json`,
                    icon: {
                        url: `${AssetsPath}facilities/delivery.svg`
                    }
                },
                commercial: {
                    url: `${DataPath}facilities/commercial.json`,
                    icon: {
                        url: `${AssetsPath}facilities/apartment.svg`
                    }
                }
            }
        }
    },
    energy: {
        regions: {
            sources: {
                allRegions: {
                    url: `${DataPath}energy/NERC_Regions_simplified.geojson`
                },
                MRO: {
                    url: `${DataPath}energy/usaEnergyRegions/MRO.geojson`
                },
                NPCC: {
                    url: `${DataPath}energy/usaEnergyRegions/NPCC.geojson`
                },
                RFC: {
                    url: `${DataPath}energy/usaEnergyRegions/RFC.geojson`
                },
                SERC: {
                    url: `${DataPath}energy/usaEnergyRegions/SERC.geojson`
                },
                SPP: {
                    url: `${DataPath}energy/usaEnergyRegions/SPP.geojson`
                },
                TRE: {
                    url: `${DataPath}energy/usaEnergyRegions/TRE.geojson`
                },
                WECC: {
                    url: `${DataPath}energy/usaEnergyRegions/WECC.geojson`
                },
                Indeterminate: {
                    url: `${DataPath}energy/usaEnergyRegions/Indeterminate.geojson`
                }
            }
        },
        powerPlants: {
            sources: {
                wind: {
                    url: `${DataPath}energy/powerPlants/wind.geojson`
                },
                solar: {
                    url: `${DataPath}energy/powerPlants/solar.geojson`
                },
                hydroelectric: {
                    url: `${DataPath}energy/powerPlants/hydroelectric.geojson`
                },
                nuclear: {
                    url: `${DataPath}energy/powerPlants/nuclear.geojson`
                },
                geothermal: {
                    url: `${DataPath}energy/powerPlants/geothermal.geojson`
                },
                fossilFuel: {
                    url: `${DataPath}energy/powerPlants/fossil-fuel.geojson`
                },
                biomass: {
                    url: `${DataPath}energy/powerPlants/biomass.geojson`
                }
            }
        }
    },
    routing: {
        trucking: `${DataPath}routing/trucking/`,
        waterways: {
            active: 'nyWaterway',
            sources: {
                nyWaterway: {
                    url: `${DataPath}routing/sea/NY copy.geojson`
                },
                flWaterway: {
                    url: `${DataPath}routing/sea/FL copy.geojson`
                },
                laWaterway: {
                    url: `${DataPath}routing/sea/LA.geojson`
                },
                caWaterway: {
                    url: `${DataPath}routing/sea/CA copy.geojson`
                },
                txWaterway: {
                    url: `${DataPath}routing/sea/TX copy.geojson`
                },
                waWaterway: {
                    url: `${DataPath}routing/sea/WA copy.geojson`
                },
                naWaterway: {
                    url: `${DataPath}routing/sea/XX copy.geojson`
                },
                gulfOfMexicoWaterway: {
                    url: `${DataPath}routing/sea/gulf-of-mexico-waterway.geojson`
                },
                gulfDeepWaterway: {
                    url: `${DataPath}routing/sea/gulf-deep-waterway.geojson`
                },
                gulfPanamaAccessWaterway: {
                    url: `${DataPath}routing/sea/gulf-panama-access-waterway.geojson`
                },
                houstonShipWaterway: {
                    url: `${DataPath}routing/sea/houston-ship-waterway.geojson`
                },
                route1Waterway: {
                    url: `${DataPath}routing/sea/waterways/route-1-waterway.geojson`
                },
                route2Waterway: {
                    url: `${DataPath}routing/sea/waterways/route-2-waterway.geojson`
                },
                route3Waterway: {
                    url: `${DataPath}routing/sea/waterways/route-3-waterway.geojson`
                },
                route4Waterway: {
                    url: `${DataPath}routing/sea/waterways/route-4-waterway.geojson`
                }
            }
        },
        shippingLanes: {
            active: 'allShippingLanes',
            sources: {
                allShippingLanes: {
                    url: `${DataPath}routing/sea/shipping-lanes-new.geojson`
                }
            }
        }
    },
    utility: {
        gasPipelines: {
            sources: {
                alliancePipelineSystem: {
                    url: `${DataPath}utility/gasPipelines/Alliance_Pipeline_System.geojson`
                },
                michonGasCo: {
                    url: `${DataPath}utility/gasPipelines/Michcon_Gas_Co.geojson`
                },
                northernBorderPLCo: {
                    url: `${DataPath}utility/gasPipelines/Northern_Border_PL_Co.geojson`
                },
                northernNaturalGas: {
                    url: `${DataPath}utility/gasPipelines/Northern_Natural_Gas_Co.geojson`
                },
                northwesternEnergyCo: {
                    url: `${DataPath}utility/gasPipelines/Northwestern_Energy_Co.geojson`
                },
                vikingGasTransmissionCo: {
                    url: `${DataPath}utility/gasPipelines/Viking_Gas_Transmission_Co.geojson`
                },
                willistonBasinInterstatePLCo: {
                    url: `${DataPath}utility/gasPipelines/Williston_Basin_Interstate_PL_Co.geojson`
                }
            }
        },
        transmissionLines: {
            sources: {
                // americanIllinoisCompany: {
                //     bbox: { north: 42.6249, west: -93.3972, south: 36.1479, east: -87.6983 },
                //     url: `${DataPath}utility/usaCompaniesTransmissionLines/americanIllinoisCompany.geojson`,
                // },
                // americanElectricPowerCoInc: {
                //     bbox: { north: 42.5749, west: -86.8455, south: 36.0675, east: -79.6033 },
                //     url: `${DataPath}utility/usaCompaniesTransmissionLines/americanElectricPowerCoInc.geojson`
                // },
                bonnevillePowerAdministration: {
                    bbox: { north: 51.2051, west: -136.2097, south: 30.7314, east: -107.2788 },
                    url: `${DataPath}utility/usaCompaniesTransmissionLines/bonnevillePowerAdministration.geojson`
                },
                consumersEnergyCo: {
                    bbox: { north: 43, west: -90.3972, south: 40.1479, east: -87 },
                    url: `${DataPath}utility/usaCompaniesTransmissionLines/consumersEnergyCo.geojson`
                },
                oncorElectricDeliveryCo: {
                    bbox: { north: 34.7325, west: -103.3603, south: 28.4371, east: -94.3267 },
                    url: `${DataPath}utility/usaCompaniesTransmissionLines/oncorElectricDeliveryCo.geojson`
                },
                tennesseeValleyAuthority: {
                    bbox: { north: 37.1431, west: -95.5793, south: 33.845, east: -78.2809 },
                    url: `${DataPath}utility/usaCompaniesTransmissionLines/tennesseeValleyAuthority.geojson`
                },
                southernCaliforniaEdison: {
                    bbox: { north: 31.0048, west: -120.4539, south: 37.7973, east: -115.1434 },
                    url: `${DataPath}utility/usaCompaniesTransmissionLines/southernCaliforniaEdison.geojson`
                }
            }
        },
        outages: {
            active: 'crew',
            sources: {
                crew: {
                    url: `${DataPath}facilities/retail.json`,
                    icon: {
                        url: `${AssetsPath}utility/crew.svg`
                    }
                },
                total: {
                    url: `${DataPath}facilities/retail.json`,
                    icon: {
                        url: `${AssetsPath}utility/crew.svg`
                    }
                }
            }
        }
    },
    telecommunications: {
        transmissionLines: {
            sources: {
                westCoast: {
                    bbox: { north: 42.5749, west: -86.8455, south: 36.0675, east: -79.6033 },
                    url: `${DataPath}telecommunications/transmission-lines/westCoast.geojson`
                },
                midwest: {
                    bbox: { north: 42.6249, west: -93.3972, south: 36.1479, east: -87.6983 },
                    url: `${DataPath}telecommunications/transmission-lines/midwest.geojson`
                },
                northEast: {
                    bbox: { north: 42.6249, west: -93.3972, south: 36.1479, east: -87.6983 },
                    url: `${DataPath}telecommunications/transmission-lines/northEast.geojson`
                },
                pacificNorthWest: {
                    bbox: { north: 42.5749, west: -86.8455, south: 36.0675, east: -79.6033 },
                    url: `${DataPath}telecommunications/transmission-lines/pacificNorthWest.geojson`
                }
            }
        },
        assets: {
            sources: {
                cell: {
                    url: `${DataPath}telecommunications/assets/cell-towers-reduced-200.geojson`,
                    icon: {
                        url: `${AssetsPath}telecommunications/antenna.svg`
                    }
                },
                data: {
                    url: `${DataPath}telecommunications/assets/fm-towers-reduced-500.geojson`,
                    icon: {
                        url: `${AssetsPath}telecommunications/server.svg`
                    }
                },
                call: {
                    url: `${DataPath}telecommunications/assets/fm-towers-reduced-990.geojson`,
                    icon: {
                        url: `${AssetsPath}telecommunications/headphones-mic.svg`
                    }
                }
            }
        },
        radioTowers: {
            active: 'ca',
            sources: {
                ca: {
                    url: `${DataPath}telecommunications/fm-towers/CA.geojson`
                },
                co: {
                    url: `${DataPath}telecommunications/fm-towers/CO.geojson`
                },
                il: {
                    url: `${DataPath}telecommunications/fm-towers/IL.geojson`
                },
                tn: {
                    url: `${DataPath}telecommunications/fm-towers/TN.geojson`
                },
                tx: {
                    url: `${DataPath}telecommunications/fm-towers/TX.geojson`
                }
            }
        },
        cellTowers: {
            active: 'mn',
            sources: {
                mn: {
                    url: `${DataPath}telecommunications/cell-towers/MN.geojson`
                },
                oh: {
                    url: `${DataPath}telecommunications/cell-towers/OH.geojson`
                },
                or: {
                    url: `${DataPath}telecommunications/cell-towers/OR.geojson`
                },
                nh: {
                    url: `${DataPath}telecommunications/cell-towers/NH.geojson`
                },
                vt: {
                    url: `${DataPath}telecommunications/cell-towers/VT.geojson`
                }
            }
        }
    },
    maritime: {
        waterways: `${DataPath}routing/sea/waterways/gulf-waterways.geojson`,
        routes: `${DataPath}maritime/routes/`,
        ports: {
            url: `${DataPath}maritime/ports.geojson`,
            icon: {
                url: `${AssetsPath}maritime/anchor.svg`
            }
        }
    }
};
