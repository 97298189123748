import { DemoId } from '../constants/sidebar';

interface Layers {
    shared: string[],
    mapsGL: string[],
    raster: string[]
}

export interface DemoLayers {
    demo: string;
    layers: Layers;
}

export const LayersMapping: DemoLayers[] = [{
    demo: DemoId.facilities,
    layers: {
        shared: ['facilities', 'alerts', 'radar'],
        mapsGL: ['lightning-strikes', 'stormcells'],
        raster: ['lightning-strikes-15m-icons', 'stormcells-hail']
    }
}, {
    demo: DemoId.aviation,
    layers: {
        shared: ['flights', 'airports', 'satellite::satellite-geocolor-states-outlines-dk'],
        mapsGL: ['radar'],
        raster: ['radar-global']
    }
}, {
    demo: DemoId.energy,
    layers: {
        shared: ['power-plants', 'radar'],
        mapsGL: ['wind-particles'],
        raster: []
    }
}, {
    demo: DemoId.routing,
    layers: {
        shared: ['radar', 'alerts-custom'],
        mapsGL: ['road-weather-surface-us'],
        raster: ['road-weather']
    }
}, {
    demo: DemoId.telecommunications,
    layers: {
        shared: ['assets', 'radar', 'satellite'],
        mapsGL: [],
        raster: []
    }
}, {
    demo: DemoId.severe,
    layers: {
        shared: ['alerts', 'radar', 'tropical-systems::active-geo', 'lightning-strikes'],
        mapsGL: [],
        raster: []
    }
}, {
    demo: DemoId.maritime,
    layers: {
        shared: ['radar', 'tropical-systems::active-geo'],
        mapsGL: ['wave-particles'],
        raster: []
    }
}];
