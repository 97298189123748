import { WebToLeadForm } from '@aerisweather/forms/dist/demos/main';
import '@aerisweather/forms/dist/demos/main.css';
import { AuthModalUrls } from '../../constants/urls';

const AuthModalForm = ({ onSubmit, onError }) => {
    const environment = process.env.APP_ENV === 'prod' ? 'production' : 'staging';

    return <WebToLeadForm
        source='demos.xweather.com'
        source_detail='/data-maps'
        environment={environment}
        recaptchaSiteKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        onSuccess={onSubmit}
        onError={onError}
        privacyUrl={AuthModalUrls.privacy}
        termsUrl={AuthModalUrls.terms}
        options={{
            form: {
                className: 'gap-y-6 text-white'
            },
            field: {
                label: {
                    className: 'text-sm'
                }
            },
            legend: {
                className: 'text-sm'
            },
            text: {},
            submit: {
                text: 'Submit',
                className: 'md:col-span-4 modal-submit'
            },
            fields: {
                givenName: {
                    validation: {
                        required: true
                    },
                    control: {
                        className: 'col-span-12 md:col-span-6'
                    }
                },
                familyName: {
                    validation: {
                        required: true
                    },
                    control: {
                        className: 'col-span-12 md:col-span-6'
                    }
                },
                company: {
                    validation: {
                        required: false
                    },
                    label: {
                        text: 'Company Name'
                    },
                    control: {
                        className: 'col-span-12 md:col-span-6'
                    }
                },
                phone: {
                    control: {
                        className: 'col-span-12 md:col-span-6'
                    }
                },
                email: {
                    label: {
                        text: 'Email'
                    },
                    control: {
                        className: 'col-span-12 '
                    }
                }
            }
        }}
    />;
};

export default AuthModalForm;
