import { getToken, parseJWTToken } from './token';
// import { getFilterLayersList } from './layers';
import { SidebarState } from '../types/sidebar';

const urlParams = new URLSearchParams(document.location.search);
let paramPromise;
let tempLayers: string;

const getParamFromUrl = (targetParam) => {
    const jwtParam = urlParams.get('token');
    const parsedToken = parseJWTToken(jwtParam);
    const currentConfig: any = parsedToken['urn:example:claim'];
    return currentConfig[targetParam];
};

const setToken = (token) => {
    const { origin, pathname } = window.location;
    const newUrl = `${origin}${pathname}?token=${token}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    urlParams.set('token', token);
};

export const mapOptsFromParams = () => {
    const jwtParam = urlParams.get('token');
    let config: any;

    if (jwtParam) {
        const parsedToken = parseJWTToken(jwtParam);
        config = parsedToken['urn:example:claim'];
    } else {
        const defaultConfig = {
            demo: 'severe',
            strategy: 'mapbox',
            weatherLayers: 'mapsGL',
            theme: 'default',
            lat: 44.8,
            lon: -93.5
        };
        getToken(defaultConfig).then((token) => {
            setToken(token);
        });
        config = defaultConfig;
    }

    return config;
};

const removeEmptyData = (data) => Object.keys(data)
    .reduce((accumulator, target) => (data[target] === ''
        ? accumulator
        : { ...accumulator, [target]: data[target] }), {});

export const setParamUrl = (paramData, token = urlParams.get('token')) => {
    const parsedToken = parseJWTToken(token);
    const currentConfig: any = parsedToken['urn:example:claim'];
    const updatedConfig = removeEmptyData({ ...currentConfig, ...paramData });
    paramPromise = getToken(updatedConfig);
    paramPromise.then((token) => {
        setToken(token);
        paramPromise = undefined;
    });
};

export const resetTokenParam = () => urlParams.set('token', '');

export const setupParamHandlers = (app, sidebarState: SidebarState) => {
    if (!app) return;

    app.map.on('change:bounds change:zoom', () => {
        const center = app.map.getCenter();
        const zoom = app.map.getZoom();
        setParamUrl({
            ...sidebarState,
            lat: center.lat,
            lon: center.lon,
            zoom
        });
    });
    app.on('layer:select', (e) => {
        const { valueSegments, key } = e.data;
        const layersList = (tempLayers || getParamFromUrl('layers'))?.split(',') ?? [];
        const layer = valueSegments ? valueSegments[0].key : key;

        if (!layersList.includes(layer)) {
            layersList.push(layer);
            const updatedLayersParam = layersList.join(',');

            if (app.tokenize) {
                setParamUrl({ ...sidebarState, layers: updatedLayersParam });
                tempLayers = '';
                // if (paramPromise) {
                //     paramPromise.then((token) => {
                //         const parsedToken = parseJWTToken(token);
                //         const currentConfig: any = parsedToken['urn:example:claim'];
                //         if (currentConfig.layers) {
                //             updatedLayersParam = `${currentConfig.layers},${updatedLayersParam}`;
                //         }

                //         setParamUrl({
                //             ...sidebarState,
                //             layers: updatedLayersParam
                //         }, token);
                //     });
                // } else {
                //     setParamUrl({ ...sidebarState, layers: updatedLayersParam });
                // }
            } else {
                tempLayers = updatedLayersParam;
            }
        }
    });

    app.on('layer:deselect', ({ data: { valueSegments, key } }) => {
        const layersList = getParamFromUrl('layers')?.split(',') ?? [];
        const filteredLayersList = layersList
            .filter((layer: string) => layer !== (valueSegments ? valueSegments[0].key : key));
        const updatedLayersParam = filteredLayersList.length > 0 ? filteredLayersList.join(',') : '';

        setParamUrl({ layers: updatedLayersParam });
    });

    app.on('layer:change', (e) => {
        let layers = getParamFromUrl('layers');

        if (typeof e.data.value !== 'string' && sidebarState.demo && e.data.previousSegments[0] && e.data.valueSegments[0]) {
            layers = layers?.replace(e.data.previousSegments[0].key, e.data.valueSegments[0].key);
        }

        if (layers) {
            setParamUrl({ ...sidebarState, layers });
        }
    });
};
