import { Theme } from "../types/sidebar";
import { ThemeId } from "../constants/sidebar";

export const capitalizeWords = (value: string) => {
    return value.replace(/\w\S*/g, (txt) => {
        return capitalizeWord(txt);
    });
};

export const capitalizeWord = (value: string) => `${value.charAt(0).toUpperCase()}${value.substring(1).toLowerCase()}`;

export const setStormTextColor = (theme: Theme) => {
    const style = document.createElement('style');

    style.textContent = `
        text[fill="#222222"] {
            fill: ${theme === ThemeId.dark || theme === ThemeId.satellite
                ? 'white'
                : '#222222'
            };
        }
    `;
    
    document.head.appendChild(style);
}