import { TelecommunicationsConfig } from './../configs/telecommunications';
import { FacilitiesConfig } from '../configs/facilities';
import { RoutingConfig } from '../configs/routing';
import { AviationConfig } from '../configs/aviation';
import { EnergyConfig } from '../configs/energy';
import { SevereConfig } from '../configs/severe';
import { MaritimeConfig } from '../configs/maritime';
import Keys from './keys';
import { Strategy } from '../types/sidebar';
import { ThemeId } from './sidebar';
import { MapStylesByStrategyUrls } from './urls';

interface Style {
    default: string;
    light?: string;
    dark?: string;
    satellite?: string;
}

interface StrategyConfig {
    accessToken?: string;
    supportedThemes: string[];
    style?: Style;
}

export const DemoConfig = {
    facilities: {
        app: FacilitiesConfig,
        sidebar: {
            theme: ThemeId.default
        }
    },
    routing: {
        app: RoutingConfig,
        sidebar: {
            theme: ThemeId.default
        }
    },
    aviation: {
        app: AviationConfig,
        sidebar: {
            theme: ThemeId.dark
        }
    },
    energy: {
        app: EnergyConfig,
        sidebar: {
            theme: ThemeId.default
        }
    },
    telecommunications: {
        app: TelecommunicationsConfig,
        sidebar: {
            theme: ThemeId.default
        }
    },
    severe: {
        app: SevereConfig,
        sidebar: {
            theme: ThemeId.default
        }
    },
    maritime: {
        app: MaritimeConfig,
        sidebar: {
            theme: ThemeId.dark
        }
    }
};

export const StrategyConfig: Record<Strategy, StrategyConfig> = {
    mapbox: {
        accessToken: Keys.mapbox,
        supportedThemes: [ThemeId.default, ThemeId.light, ThemeId.dark, ThemeId.satellite],
        style: MapStylesByStrategyUrls.mapbox.style
    },
    google: {
        accessToken: Keys.google,
        supportedThemes: [ThemeId.default, ThemeId.dark, ThemeId.satellite]
    },
    maplibre: {
        supportedThemes: [ThemeId.default],
        style: MapStylesByStrategyUrls.maplibre.style
    },
    leaflet: {
        supportedThemes: [ThemeId.default, ThemeId.dark],
        style: MapStylesByStrategyUrls.leaflet.style
    }
};
