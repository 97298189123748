import { Icon } from '@chakra-ui/react';

export const ShareIcon = (props) => <Icon viewBox='0 0 20 20' {...props}>
    <path d="M19.1667 18.3333H17.5V1.66667H3.33333V0H18.3333C18.7933 0 19.1667 0.373333 19.1667 0.833333V18.3333Z" />
    <path d="M15 3.33331H1.66666C1.20666 3.33331 0.833328 3.70665 0.833328 4.16665V19.1666C0.833328 19.6266 1.20666 20 1.66666 20H15C15.46 20 15.8333 19.6266 15.8333 19.1666V4.16665C15.8333 3.70665 15.46 3.33331 15 3.33331ZM9.16666 15.8333H4.16666V14.1666H9.16666V15.8333ZM12.5 12.5H4.16666V10.8333H12.5V12.5ZM12.5 9.16665H4.16666V7.49998H12.5V9.16665Z" />
</Icon>;

export const InfoIcon = (props) => <Icon viewBox='0 0 20 20' {...props}>
    <path d="M10 19.1666C15.0626 19.1666 19.1667 15.0626 19.1667 9.99998C19.1667 4.93737 15.0626 0.833313 10 0.833313C4.93738 0.833313 0.833328 4.93737 0.833328 9.99998C0.833328 15.0626 4.93738 19.1666 10 19.1666Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M9.99999 15.8333C10.4602 15.8333 10.8333 15.4602 10.8333 15C10.8333 14.5397 10.4602 14.1666 9.99999 14.1666C9.53975 14.1666 9.16666 14.5397 9.16666 15C9.16666 15.4602 9.53975 15.8333 9.99999 15.8333Z" fill="white" />
    <path d="M8.21083 5.46666C9.86083 4.73749 11.9958 4.82416 12.725 6.00416C13.4542 7.18416 12.9508 8.55665 11.7008 9.61582C10.4508 10.675 10 11.25 10 12.0833" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
</Icon>;
