import { DemoId, WeatherLayersId } from '../constants/sidebar';
import { baseMap } from './common/common';
import { timeline } from './common/timeline';
import { DataSources } from '../constants/dataSources';
import { initializeLayersPanel } from '../utils/layers';
import { DegreeDays } from './common/degreeDays';
import { Impacts } from './common/impacts';
import { radarButton, satelliteButton, alertsButton, stormCellsSegments } from './common/buttons';
import { defaultOverrides, alertsButtonGL, airQualityIndexButtonGL, radarButtonGL } from './common/mapsGL';
import { Forecast } from './common/forecast';
import { StormReports } from './common/stormReports';

let _aeris;
let lastCoord;
let theme;
const facilities = DataSources.facilities;

const assetInfoPanelView = {
    request: (data) => {
        const request = _aeris.api();
        const forecastFields = 'timestamp,dateTimeISO,tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN, maxTempC, minTempC, maxTempF, minTempF'
            .split(',')
            .map((key) => `periods.${key}`);
        request.addRequest(_aeris.api().endpoint('conditions'));
        request.addRequest(
            _aeris.api().endpoint('forecasts').fields(forecastFields.join(',')).filter('1day')
                .limit(7)
        );
        request.addRequest(
            _aeris.api().endpoint('threats').radius('50mi')
        );
        request.addRequest(
            _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
        );
        request.addRequest(_aeris.api().endpoint(
            'phrases/summary'
        ));
        request.addRequest(
            _aeris.api().endpoint('convective/outlook').action('contains')
        );
        request.addRequest(
            _aeris.api().endpoint('lightning/summary').action('closest').radius('60mi')
                .limit(100)
        );

        return request;
    },
    views: [{
        data: (data) => {
            if (_aeris.utils.isEmpty(data)) return null;
            return data;
        },
        renderer: (data) => {
            if (!data) return;

            if (data.forecasts) {
                return DegreeDays({ forecastsPeriods: data.forecasts[0].periods, theme });
            }
        }
    }, {
        title: 'Active Alerts',
        renderer: 'alerts'
    },
    {
        title: 'Active Threats',
        renderer: 'threats'
    },
    {
        data: (data) => {
            if (_aeris.utils.isEmpty(data)) return null;
            return data;
        },
        renderer: (data) => {
            if (!data) {
                return '';
            }
            return Impacts({ lastCoord });
        }
    },
    {
        title: 'Current Conditions',
        renderer: 'obs'
    },
    {
        data: (data) => {
            if (_aeris.utils.isEmpty(data)) return null;
            return data;
        },
        renderer: (data) => {
            if (data?.forecasts) {
                return Forecast({ forecasts: data.forecasts });
            }
            return '';
        }
    }]
};

const rasterLayers = [{
    title: 'Conditions',
    buttons: [radarButton,
        {
            id: 'air-quality-index',
            title: 'Air Quality',
            value: 'air-quality-index'
        }]
}, {
    title: 'Severe',
    buttons: [
        alertsButton,
        stormCellsSegments,
        {
            id: 'stormreports',
            title: 'Storm Reports',
            value: 'stormreports'
        }, {
            title: 'Lightning Strikes',
            value: 'lightning-strikes-15m-icons'
        }]
}, {
    title: 'Other',
    buttons: [{
        id: 'fires', title: 'Fires', value: 'fires-obs'
    }]
}];

const mapsGLConfig = {
    layers: [{
        title: 'Conditions',
        buttons: [radarButtonGL,
            airQualityIndexButtonGL,
            {
                title: 'Winds',
                value: 'wind',
                options: {
                    ...defaultOverrides,
                    paint: {
                        sample: {
                            colorscale: {
                                normalized: true,
                                stops: [0, '#fff', 1, '#fff']
                            }
                        },
                        particle: {
                            size: 1,
                            count: Math.pow(128, 2)
                        }
                    }
                },
                segments: [{
                    title: 'Particles', value: 'wind-particles'
                }, { title: 'Barbs', value: 'wind-barbs' }]
            }]
    }, {
        title: 'Severe',
        buttons: [
            alertsButtonGL,
            {
                id: 'stormcells',
                title: 'Storm Cells',
                value: 'stormcells'
                // options: {
                //     data: {
                //         evaluator: {
                //             title: 'Storm Cells',
                //             fn: (value) => {
                //                 if (value.length === 1) {
                //                     return 'storm approaching!';
                //                     // return title(value[0].traits.type) + ' storm approaching!';
                //                 }
                //                 else if (value.length > 1) {
                //                     return value.length + ' storms approaching!';
                //                 }
                //                 return 'No storms';
                //             },
                //         },
                //     },
                // }
            }, {
                id: 'stormreports',
                title: 'Storm Reports',
                value: 'stormreports'
            }, {
                title: 'Lightning Strikes',
                value: 'lightning-strikes'
            }]
    }, {
        title: 'Other',
        buttons: [{
            id: 'fires',
            title: 'Fires',
            value: 'fires-obs'
        }]
        // {
        //     id: 'lightning',
        //     title: 'Lightning',
        //     segments: [{
        //         title: 'Lightning Strikes',
        //         value: 'lightning-strikes-15m-icons'
        //     },
        //     {
        //         title: 'Lightning Threats',
        //         value: 'lightning-threats'
        //     }]
        // }, {
    }]
};

export const FacilitiesConfig = ({ theme, layers, weatherLayers }) => ({
    app: {
        ...baseMap,
        mode: 'light',
        panels: {
            layers: {
                toggleable: true,
                buttons: [{
                    id: 'facilities',
                    title: 'Facilities',
                    filter: true,
                    segments: [{
                        title: 'Retail',
                        value: 'retail'
                    }, {
                        title: 'Distribution Centers',
                        value: 'distributionCenters'
                    }, {
                        title: 'Data Centers',
                        value: 'dataCenters'
                    }]
                },
                {
                    id: 'assets',
                    title: 'Insured Assets',
                    filter: true,
                    segments: [{
                        title: 'Home',
                        value: 'home'
                    }, {
                        title: 'Auto',
                        value: 'auto'
                    }, {
                        title: 'Commercial',
                        value: 'commercial'
                    }]
                },
                {
                    title: 'Satellite',
                    buttons: [
                        satelliteButton
                    ]
                },
                {
                    title: 'Tropical (Testing)',
                    buttons: [{
                        id: 'tropicaltest',
                        title: 'Tropical Test System',
                        value: 'tropical-cyclones',
                        options: {
                            data: {
                                request: {
                                    endpoint: 'tropicalcyclones',
                                    parameters: {
                                        filter: 'test,geo'
                                    }
                                },
                            },
                        }
                    }, {
                        id: 'coastalalertstest',
                        value: 'tropical-cyclones-break-points',
                        title: 'Coastal Alerts (Test)',
                        options: {
                            data: {
                                request: {
                                    parameters: {
                                        filter: 'geo,test'
                                    }
                                }
                            }
                        }
                    }]
                },
                ...(weatherLayers === 'raster' ? rasterLayers : [])]
            },
            ...timeline,
            info: {
                views: {
                    asset: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields = 'timestamp,dateTimeISO,tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN, maxTempC, minTempC, maxTempF, minTempF'
                                .split(',')
                                .map((key) => `periods.${key}`);
                            request.addRequest(_aeris.api().endpoint('conditions'));
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').fields(forecastFields.join(','))
                            );
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            request.addRequest(
                                _aeris.api().endpoint('convective/outlook').action('contains')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('lightning/summary').action('closest').radius('60mi')
                                    .limit(100)
                            );
                            request.addRequest(
                                _aeris.api().endpoint('stormreports').from('-30days').to('now')
                                    .limit(10)
                            );

                            return request;
                        },
                        views: [{
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ lastCoord });
                            }
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (data?.forecasts) {
                                    return Forecast({ forecasts: data.forecasts });
                                }
                                return '';
                            }
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (data.stormreports === undefined) {
                                    return '';
                                }
                                return StormReports({ stormReports: data.stormreports });
                            }
                        }]
                    },
                    facility: assetInfoPanelView,
                    localweather: assetInfoPanelView
                }
            }
        }
    },
    events: {
        ready: (aeris, app) => {
            _aeris = aeris;
            theme = theme;
            const active = {
                facilities: 'retail',
                assets: 'home'
            };

            app.map.on('click', (e) => {
                lastCoord = e.data.coord;
                app.showInfoAtCoord(e.data.coord, 'localweather', 'Local Weather');
            });

            app.addSource('facilities', 'vector', {
                data: {
                    url: (params) => {
                        active.facilities = params.filter || active.facilities;

                        return facilities.facilities.sources[active.facilities].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    marker: () => {
                        const icon = facilities.facilities.sources[active.facilities].icon;
                        return {
                            className: 'facilities',
                            icon: {
                                url: icon.url
                            },
                            size: [24, 24]
                        };
                    }
                }
            });

            app.addSource('assets', 'vector', {
                data: {
                    url: (params) => {
                        active.assets = params.filter || active.assets;
                        return facilities.assets.sources[active.assets].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    marker: () => {
                        const icon = facilities.assets.sources[active.assets].icon;
                        return {
                            className: 'assets',
                            icon: {
                                url: icon.url
                            },
                            size: [24, 24]
                        };
                    }
                }
            });

            app.map.on('marker:click', (e) => {
                if (e.data) {
                    lastCoord = e.data.marker.coordinate;
                    const data = e.data.data || {};
                    const source = data.awxjs_source;

                    if (source === 'facilities') {
                        const loc = `${data.lat},${data.lon}`;
                        const name = _aeris.utils.strings.toName(data.name);
                        app.showInfo('facility', name).load({
                            p: loc
                        });
                    }

                    if (source === 'assets') {
                        const loc = `${data.lat},${data.lon}`;
                        const name = _aeris.utils.strings.toName(data.name);
                        app.showInfo('asset', name).load({
                            p: loc
                        });
                    }
                }
            });

            aeris.modules().then((modules) => {
                app.modules.insertAt(4, modules.tropical.Systems, {
                    controls: {
                        segments: [{
                            title: 'Active Cyclones',
                            value: 'active,geo'
                        }, {
                            title: 'All Cyclones',
                            value: 'geo',
                            options: {
                                data: {
                                    request: {
                                        parameters: {
                                            from: '1/1/2022',
                                            to: '4/30/2023',
                                            limit: 100,
                                            save: false
                                        }
                                    }
                                }
                            }
                        }]
                    }
                });

                app.modules.insertAt(5, modules.tropical.Invests);
                // app.modules.insertAt(6, modules.tropical.Windfields);
                // app.modules.insertAt(7, modules.tropical.Breakpoints);
                initializeLayersPanel(
                    aeris,
                    app,
                    {
                        demo: DemoId.facilities,
                        weatherLayers
                    },
                    {
                        mapsGLConfig,
                        layersFromParams: layers
                    }
                ).then(({ controller }) => {
                    app.on('layer:deselect', (e) => {
                        const { id } = e.data;

                        if (id === 'stormcells' && weatherLayers === WeatherLayersId.mapsGL) {
                            controller.removeWeatherLayer('stormcells');
                        }
                    });
                });
            });
        }
    }
});
