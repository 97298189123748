import { Box, Button, Image, HStack } from '@chakra-ui/react';
import DarkMapImage from '../../assets/images/dark.png';
import DefaultMapImage from '../../assets/images/default.png';
import LightMapImage from '../../assets/images/light.png';
import SatelliteMapImage from '../../assets/images/satellite.png';
import { StrategyConfig } from '../../constants/configMapping';
import { ThemeId } from '../../constants/sidebar';

interface MapThemeControlProps {
    theme: string;
    strategy: string;
    onClick: ({ control, value }) => void;
}

const images = [{
    id: ThemeId.default, src: DefaultMapImage, alt: 'Default Map'
}, { id: ThemeId.light, src: LightMapImage, alt: 'Light Map' },
{ id: ThemeId.dark, src: DarkMapImage, alt: 'Dark Map' },
{ id: ThemeId.satellite, src: SatelliteMapImage, alt: 'Satellite Map' }];

export const MapThemeControl = ({ strategy, theme, onClick }: MapThemeControlProps) => {
    const isDisabled = (imageId: string) => !StrategyConfig[strategy].supportedThemes.includes(imageId);

    const getDynamicImageProps = (imageId: string) => (
        isDisabled(imageId)
            ? undefined
            : { _hover: { borderColor: theme === imageId ? 'brand.main' : 'gray.500' } }
    );

    return (
        <Box py={{ base: 2, md: 3, xl: 4 }}>
            <HStack spacing='10px'>
                {images.map((image) => (
                    <Button
                        key={image.id}
                        isDisabled={isDisabled(image.id)}
                        p='0'
                        bg='transparent'
                        _hover={{ bg: 'transparent' }}
                        onClick={() => onClick({ control: 'theme', value: image.id })}>
                        <Image
                            src={image.src}
                            alt={image.alt}
                            p='2px'
                            border='2px'
                            borderColor={theme === image.id ? 'brand.main' : 'gray.200'}
                            borderRadius='3px'
                            {...getDynamicImageProps(image.id)}
                        />
                    </Button>
                ))}
            </HStack>
        </Box>
    );
};
