import format from 'date-fns/format';

import { Forecast } from './common/forecast';
import { baseMap } from './common/common';
import { radarButton, alertsButton, satelliteButton, temperaturesButton } from './common/buttons';
import { timeline } from './common/timeline';
import { DataSources } from '../constants/dataSources';
import { initializeLayersPanel } from '../utils/layers';
import { DemoId, WeatherLayersId, ThemeId } from '../constants/sidebar';
import { Impacts } from './common/impacts';
import { defaultOverrides, temperaturesButtonGL, radarButtonGL, alertsButtonGL } from './common/mapsGL';
// import { addLightningThreats } from './common/sources/addLightningThreats';

let _aeris;
const telecommunications = DataSources.telecommunications;

let currentTimestamp = Math.floor(Date.now() / 1000);
let mouseClickCoord;
let mouseCoord;
let shapeClicked;
let lastCoord;

const rasterLayers = [
    {
        title: 'Conditions',
        buttons: [radarButton, temperaturesButton]
    },
    {
        title: 'Severe',
        buttons: [
            alertsButton,
            {
                title: 'Lightning Strikes',
                value: 'lightning-strikes-15m-icons'
            },
            // {
            //     id: 'lightning',
            //     title: 'Lightning',
            //     segments: [{
            //         title: "Lightning Strikes",
            //         value: "lightning-strikes-15m-icons"
            //     },
            //     {
            //         title: "Lightning Threats",
            //         value: "lightning-threats"
            //     },]
            // },
            {
                id: 'stormcells',
                title: 'Storm Cells',
                value: 'stormcells',
                options: {
                    data: {
                        evaluator: {
                            title: 'Storm Cells',
                            fn: (value) => {
                                if (value.length === 1) {
                                    return 'storm approaching!';
                                    // return title(value[0].traits.type) + ' storm approaching!';
                                }
                                else if (value.length > 1) {
                                    return value.length + ' storms approaching!';
                                }
                                return 'No storms';
                            },
                        },
                    },
                }
            }
        ]
    },
]

const mapsGLConfig = {
    layers: [
        {
            title: 'Conditions',
            buttons: [
                radarButtonGL,
                temperaturesButtonGL,
                {
                    title: 'Winds',
                    value: 'wind',
                    options: {
                        ...defaultOverrides,
                        paint: {
                            sample: {
                                colorscale: {
                                    normalized: true,
                                    stops: [
                                        0, '#fff',
                                        1, '#fff'
                                    ]
                                }
                            },
                            particle: {
                                size: 1,
                                count: Math.pow(128, 2)
                            }
                        }
                    },
                    segments: [{
                        title: 'Particles', value: 'wind-particles'
                    },
                    { title: 'Barbs', value: 'wind-barbs' }]
                }]
        },
        {
            title: 'Severe',
            buttons: [
                alertsButtonGL,
                {
                    title: 'Lightning Strikes',
                    value: 'lightning-strikes'
                },
                // {
                //     id: 'lightning',
                //     title: 'Lightning',
                //     segments: [{
                //         title: 'Lightning Strikes',
                //         value: 'lightning-strikes'
                //     },
                //     {
                //         title: 'Lightning Threats',
                //         value: 'lightning-threats'
                //     },]
                // },
                {
                    id: 'stormcells',
                    title: 'Storm Cells',
                    value: 'stormcells',
                    options: {
                        data: {
                            evaluator: {
                                title: 'Storm Cells',
                                fn: (value) => {
                                    if (value.length === 1) {
                                        return 'storm approaching!';
                                        // return title(value[0].traits.type) + ' storm approaching!';
                                    }
                                    else if (value.length > 1) {
                                        return value.length + ' storms approaching!';
                                    }
                                    return 'No storms';
                                }
                            }
                        }
                    }
                }
            ]
        }]
};

export const TelecommunicationsConfig = ({ layers, weatherLayers, theme }) => ({
    app: {
        ...baseMap,
        mode: 'light',
        panels: {
            layers: {
                toggleable: true,
                buttons: [
                    {
                        id: 'transmission-lines',
                        title: 'Fibre Optic Cable',
                        filter: true,
                        segments: [{ title: 'West Coast', value: 'westCoast' },
                        { title: 'Midwest', value: 'midwest' },
                        { title: 'Northeast', value: 'northEast' }]
                    },
                    {
                        id: 'assets',
                        title: 'Assets',
                        filter: true,
                        segments: [{
                            title: 'Cell Towers', value: 'cell'
                        },
                        { title: 'Datacenters', value: 'data' },
                        { title: 'Call Centers', value: 'call' }]
                    },
                    {
                        title: 'Satellite',
                        buttons: [
                            satelliteButton
                        ]
                    },
                    ...(weatherLayers === 'raster' ? rasterLayers : [])]
            },
            ...timeline,
            info: {
                views: {
                    asset: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields =
                                "timestamp,dateTimeISO, minTempC, maxTempC, minTempF, maxTempF, tempC, tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN"
                                    .split(",")
                                    .map(key => `periods.${key}`);

                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('forecasts')
                                    .fields(forecastFields.join(','))
                            );
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            request.addRequest(
                                _aeris.api().endpoint('convective/outlook').action('contains')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('lightning/summary').action('closest').radius('60mi')
                                    .limit(100)
                            );
                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('lightning/threats')
                            );

                            return request;
                        },
                        views: [{
                            title: 'Active Lighting Threat',
                            requiresData: true,
                            data: (data) => {
                                if (data?.lightning_threats?.length > 0) return data;
                                return null;
                            },
                            renderer: (data) => {
                                if (!data.hasOwnProperty('lightning_threats')) {
                                    return '';
                                }

                                const details = data.lightning_threats[0]?.details;
                                const startTime = details?.range?.minDateTimeISO;
                                const endTime = details?.range?.maxDateTimeISO;
                                let startTimeText = '';
                                let endTimeText = '';

                                if (details?.range) {
                                    startTimeText = format(new Date(startTime), 'MM/dd\' at \'hh:mm a');
                                    endTimeText = format(new Date(endTime), 'MM/dd\' at \'hh:mm a');
                                }

                                return `
                                        ${details?.severe
                                        ? "<div class='awxjs__app__ui-panel-info__alert-name mb-1'><strong>Severe</strong></div>"
                                        : ""}
                                            <div class="coords awxjs__app__ui-panel-info__alert">
                                            <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Storm ID:</div>
                                                    <div class="awxjs__ui-expand value">
                                                        ${details?.stormId}
                                                    </div>
                                                </div>
                                                <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Movement:</div>
                                                    <div class="awxjs__ui-expand value">
                                                        Heading ${details?.movement?.dir}
                                                    </div>
                                                </div>
                                                <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Speed</div>
                                                    <div class="awxjs__ui-expand value">
                                                        ${details?.movement?.speedMPH}mph
                                                    </div>
                                                </div>
                                                <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Threat Period</div>
                                                    <div class="awxjs__ui-expand value">
                                                    From ${startTimeText} until ${endTimeText}
                                                    </div>
                                                </div>
                                            </div>`;
                            }
                        }, {
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ lastCoord });
                            }
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            // requiresData property
                            renderer: (data) => {
                                if (data?.forecasts) {
                                    return Forecast({ forecasts: data.forecasts });
                                }
                                return '';
                            }
                        }]
                    },
                    localweather: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields = 'timestamp,dateTimeISO, minTempC, maxTempC, minTempF, maxTempF, tempC, tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN'
                                .split(',')
                                .map((key) => `periods.${key}`);
                            request.addRequest(_aeris.api().endpoint('conditions'));
                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('forecasts')
                                    .fields(forecastFields.join(','))
                            );
                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('threats')
                                    .radius('50mi')
                            );
                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('alerts')
                                    .fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            // request.addRequest(
                            //     _aeris
                            //         .api()
                            //         .endpoint("convective/outlook")
                            //         .action("contains")
                            // );
                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('lightning/summary')
                                    .action('closest')
                                    .radius('60mi')
                                    .limit(100)
                            );
                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('places')
                            );
                            request.addRequest(
                                _aeris
                                    .api()
                                    .endpoint('lightning/threats')
                            );

                            return request;
                        },
                        views: [{
                            renderer: 'place'
                        }, {
                            title: 'Active Lighting Threat',
                            requiresData: true,
                            data: (data) => {
                                if (data?.lightning_threats?.length > 0) return data;
                                return null;
                            },
                            renderer: (data) => {
                                if (!data.hasOwnProperty('lightning_threats')) {
                                    return '';
                                }

                                const details = data.lightning_threats[0]?.details;
                                const startTime = details?.range?.minDateTimeISO;
                                const endTime = details?.range?.maxDateTimeISO;
                                let startTimeText = '';
                                let endTimeText = '';

                                if (details?.range) {
                                    startTimeText = format(new Date(startTime), 'MM/dd\' at \'hh:mm a');
                                    endTimeText = format(new Date(endTime), 'MM/dd\' at \'hh:mm a');
                                }

                                return `
                                        ${details?.severe
                                        ? "<div class='awxjs__app__ui-panel-info__alert-name mb-1'><strong>Severe</strong></div>"
                                        : ""}
                                            <div class="coords awxjs__app__ui-panel-info__alert">
                                            <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Storm ID:</div>
                                                    <div class="awxjs__ui-expand value">
                                                        ${details?.stormId}
                                                    </div>
                                                </div>
                                                <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Movement:</div>
                                                    <div class="awxjs__ui-expand value">
                                                        Heading ${details?.movement?.dir}
                                                    </div>
                                                </div>
                                                <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Speed</div>
                                                    <div class="awxjs__ui-expand value">
                                                        ${details?.movement?.speedMPH}mph
                                                    </div>
                                                </div>
                                                <div class="awxjs__ui-row">
                                                    <div class="awxjs__ui-expand">Threat Period</div>
                                                    <div class="awxjs__ui-expand value">
                                                    From ${startTimeText} until ${endTimeText}
                                                    </div>
                                                </div>
                                            </div>`;
                            }
                        }, {
                            renderer: 'units'
                        }, {
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ lastCoord });
                            }
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (data?.forecasts) {
                                    return Forecast({ forecasts: data.forecasts });
                                }
                                return '';
                            }
                        }]
                    }
                }
            }
        }
    },
    events: {
        ready: (aeris, app) => {
            _aeris = aeris;
            const active = {
                transmissionLines: 'westCoast',
                assets: 'cell'
            };

            app.map.on('click', (e) => {
                mouseClickCoord = e.data.coord;
                mouseCoord = e.data.coord;
                lastCoord = e.data.coord;
                app.showInfoAtCoord(e.data.coord, 'localweather', 'Local Weather');
            });

            app.addSource('transmission-lines', 'geojson', {
                data: {
                    url: (params) => {
                        active.transmissionLines = params.filter || active.transmissionLines;
                        return telecommunications.transmissionLines.sources[active.transmissionLines].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    polyline: {
                        stroke: {
                            color: theme === ThemeId.dark || theme === ThemeId.satellite ? '#b0adc7' : '#727080',
                            width: 2
                        }
                    }
                }
            });
            // addLightningThreats(aeris, app);


            app.addSource('assets', 'geojson', {
                data: {
                    url: (params) => {
                        active.assets = params.filter || active.assets;
                        return telecommunications.assets.sources[active.assets].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    marker: () => {
                        const icon = telecommunications.assets.sources[active.assets].icon;
                        return {
                            className: 'assets',
                            icon: {
                                url: icon.url
                            },
                            size: [24, 24]
                        };
                    }
                }
            });

            app.map.on('mousemove', (e) => {
                const { lat, lng } = e.data.coord;
                mouseCoord = { lat, lng };
            });

            // app.map.on('shape:click', (e) => {
            //     if (e.data) {
            //         const data = e.data.data || {};
            //         const source = data.awxjs_source;
            //         if (source === 'lightning-threats') {
            //             shapeClicked = {
            //                 ts: Date.now(),
            //                 data: data
            //             };
            //         }
            //     }
            // });

            app.map.on('marker:click', (e) => {
                if (e.data) {
                    const data = e.data.data || {};
                    const source = data.awxjs_source;

                    if (source === 'assets') {
                        lastCoord = e.data.marker.coordinate;
                        const { lat, lon } = e.data.marker.coordinate;
                        const loc = `${lat},${lon}`;
                        const title = data.Licensee ? `${data.Licensee} - ${data.LocCity}, ${data.LocState}` : `Asset ${data.OBJECTID}`;
                        app.showInfo('asset', title)
                            .load({ p: loc });
                    }
                }
            });

            initializeLayersPanel(
                aeris,
                app,
                {
                    demo: DemoId.telecommunications,
                    weatherLayers
                },
                {
                    mapsGLConfig,
                    layersFromParams: layers
                }
            ).then(({ controller }) => {
                app.on('layer:deselect', (e) => {
                    const { id } = e.data;

                    if (id === 'stormcells' && weatherLayers === WeatherLayersId.mapsGL) {
                        controller.removeWeatherLayer('stormcells');
                    }
                });
            });
        }
    }
});
