import { Select } from '@chakra-ui/react';
import { Strategy } from '../../types/sidebar';

interface StrategyOption {
    label: string;
    value: Strategy;
}

interface MapStrategyProps {
    strategySelected: Strategy;
    strategies: StrategyOption[]
    onClick: ({ control, value }: { control: 'strategy', value: string }) => void;
}

export const MapStrategyControl = ({ strategySelected, strategies, onClick }: MapStrategyProps) => (
    <Select
        border='0'
        size='sm'
        fontWeight='bold'
        onChange={(e) => onClick({ control: 'strategy', value: e?.target?.value })}
        value={strategySelected}>
        {strategies.map((strategy) => (
            <option
                key={strategy.value}
                value={strategy.value}>
                {strategy.label}
            </option>))}
    </Select>
);
