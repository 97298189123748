import { useState, useEffect, useRef } from 'react';
import { ChakraProvider, Link } from '@chakra-ui/react';
import { useAuth0, User } from '@auth0/auth0-react';

import useSidebarDisclosure from '../hooks/useSidebarDisclosure';
import useModalDisclosure from '../hooks/useAuthModalDisclosure';
import useCookie from '../hooks/useCookie';
import Sidebar from './Sidebar/Sidebar';
import AuthModal from './AuthModal/AuthModal';
import { SidebarTheme } from '../theme/SidebarTheme';
import { DemoConfig, StrategyConfig } from '../constants/configMapping';
import { BaseConfig } from '../configs/base';
import { GoogleMapDarkStyles } from '../constants/googleMapStyles';
import { setupParamHandlers, mapOptsFromParams, resetTokenParam, setParamUrl } from '../utils/parameters';
import keys from '../constants/keys';
import { ThemeId } from '../constants/sidebar';
import { SidebarState, Demo, Theme, Strategy, WeatherLayers } from '../types/sidebar';
import AuthModalForm from './AuthModal/AuthModalForm';
import { Logo } from '../assets/icons/sidebar/xweatherLogo';
import { XWeatherUrl } from '../constants/urls';
import { setStormTextColor } from '../utils/text';

interface Opts {
    current: {
        demo: Demo,
        theme: Theme,
        strategy: Strategy,
        weatherLayers: WeatherLayers
        layers?: string,
        lat: number,
        lon: number,
        zoom: number
    }
}

const App = () => {
    const { isAuthenticated, isLoading } = useAuth0<User>();
    const aeris: any = useRef(new window.aeris(keys.aeris.id, keys.aeris.secret));
    const opts: Opts = useRef(mapOptsFromParams());
    const app: any = useRef();
    const hasLoaded: { current: boolean } = useRef(false);

    const [allowAccess, setAllowAccess] = useState<boolean>(false);
    const { hasCookie, setCookies } = useCookie();
    const { isSidebarOpen, onSidebarOpen, onSidebarClose } = useSidebarDisclosure();
    const { isAuthModalOpen = false, onAuthModalOpen, onAuthModalClose } = useModalDisclosure();
    const [sidebarState, setSidebarState] = useState<SidebarState>({
        demo: opts.current.demo,
        theme: opts.current.theme,
        strategy: opts.current.strategy,
        weatherLayers: opts.current.weatherLayers
    });

    useEffect(() => {
        if (hasCookie || isAuthenticated) {
            setAllowAccess(true);
            handleSidebarOpen();
            onAuthModalClose();
        } else {
            if (!isLoading) {
                onAuthModalOpen();
            }
        }
    }, [hasCookie, isAuthenticated, isLoading]);

    useEffect(() => {
        if (!hasLoaded.current) {
            hasLoaded.current = true;
        } else {
            resetTokenParam();
        }
    }, [sidebarState.demo, sidebarState.weatherLayers]);

    useEffect(() => {
        loadApp();
    }, [sidebarState, allowAccess]);

    const onError = (error) => {
        console.log(error);
    };

    const loadApp = () => {
        setStormTextColor(sidebarState.theme);

        aeris.current.apps((apps): void => {
            opts.current = mapOptsFromParams();
            const config = allowAccess ? getConfig() : BaseConfig();
            app.current = new apps.InteractiveMapApp(document.querySelector('#app'), config.app);
            addCustomEventHandlers(config);
            setupParamHandlers(app.current, sidebarState);
        });
    };

    const addCustomEventHandlers = (config): void => {
        Object.keys(config.events).forEach((event) => {
            app.current?.on(event, (e) => {
                config.events[event](aeris.current, app.current, e);
            });
        });
        ['click', 'marker:click'].forEach((event) => {
            app.current.map.on(event, () => {
                handleSidebarClose();
            });
        });
    };

    const getConfig = () => {
        const { demo, theme, strategy, weatherLayers }: SidebarState = sidebarState;
        const { lat, lon, zoom }: { lat: number, lon: number, zoom: number } = opts.current;
        const layers: string[] = opts.current.layers?.split(',') ?? [];
        const config = DemoConfig[demo].app({
            strategy,
            theme,
            weatherLayers,
            layers,
            sidebarControls: {
                onSidebarOpen: handleSidebarOpen,
                onSidebarClose: handleSidebarClose
            }
        });
        const map: any = {
            ...config.app.map,
            strategy,
            style: StrategyConfig[strategy]?.style?.[theme],
            accessToken: StrategyConfig[strategy].accessToken,
            zoom: zoom || config.app.map.zoom,
            center: { lat, lon }
        };

        if (strategy === 'google') {
            map.map.mapTypeId = theme === ThemeId.satellite ? 'satellite' : 'roadmap';
            map.map.styles = theme === ThemeId.dark ? GoogleMapDarkStyles : undefined;
        }

        config.app.mode = theme === ThemeId.dark ? 'dark' : 'light';
        config.app.map = map;

        return config;
    };

    const handleSidebarStateChange = ({ control, value }) => {
        if (value !== undefined && sidebarState[control] !== value) {
            let newState: SidebarState = { ...sidebarState, [control]: value };

            if (control === 'strategy' && !StrategyConfig[value].supportedThemes.includes(sidebarState.theme)) {
                newState = { ...newState, theme: 'default' };
            }

            if (control === 'demo') {
                newState = { ...newState, ...DemoConfig[value].sidebar };
            }

            setParamUrl({ ...newState });

            setSidebarState((prev) => ({ ...prev, ...newState }));
        }
    };

    const resetLegendStyle = (isSidebarOpen: boolean) => {
        let wrapper: HTMLElement | null = document.getElementById('wrapper');
        wrapper?.classList.add(isSidebarOpen ? 'sidebar-open' : 'sidebar-closed');
        wrapper?.classList.remove(isSidebarOpen ? 'sidebar-closed' : 'sidebar-open');
    };

    const handleSidebarClose = () => {
        resetLegendStyle(false);
        onSidebarClose();
    };

    const handleSidebarOpen = () => {
        resetLegendStyle(true);
        onSidebarOpen();
    };

    return <ChakraProvider theme={SidebarTheme}>
        <AuthModal authModalControl={{ isOpen: isAuthModalOpen, onOpen: onAuthModalOpen, onClose: onAuthModalClose }}>
            {!hasCookie && <AuthModalForm onSubmit={setCookies} onError={onError} />}
        </AuthModal>

        {
            (!isSidebarOpen || isAuthModalOpen) && <Link
                className='logo-link'
                href={XWeatherUrl}
                position='absolute'
                zIndex={401}
                p={{ base: 2, md: 3, xxl: 4 }}>
                <Logo
                    filter='drop-shadow(rgb(0 0 0 / 0.6) -1px 1px 2px )'
                    _hover={{
                        filter: 'drop-shadow(rgb(0 0 0 / 0.8) -1px 1px 2px )'
                    }} />
            </Link>
        }

        <div id='wrapper'>
            {allowAccess
                && <div className='sidebar-container'>
                    <Sidebar
                        drawerControl={{ isOpen: isSidebarOpen, onOpen: handleSidebarOpen, onClose: handleSidebarClose }}
                        sidebarState={sidebarState}
                        onSidebarStateChange={handleSidebarStateChange}
                    />
                </div>}
            <div id='app'></div>
        </div>
    </ChakraProvider>;
};

export default App;
