import { format } from "date-fns";
import { capitalizeWords, capitalizeWord } from "../../utils/text";

const units = {
    'wind': 'mph',
    'rain': 'in',
    'hail': 'in'
};

const StormReport = (stormReport) => {
    const { place: { name, state }, report: { detail: { text }, dateTimeISO, type, comments, cat } } = stormReport;
    const date = new Date(dateTimeISO);
    const formattedDate = format(date, 'MM/dd/yy\' at \'hh:mm a');
    const unitsText = text ? ` - ${text.replace('.00', '')} ${units[cat] ?? ''}` : '';
    return `
        <div class="d-flex flex-column border-top storm-report__row">
            <div class='d-flex'>
                <div class="mb-1 mr-2">
                    <span class="font-weight-bold">${capitalizeWords(type)}</span><span class="text-nowrap">${unitsText}</span>
                </div>
                <span class="awxjs__app__ui-panel-info__obs-timestamp mb-0 ml-auto text-nowrap">${formattedDate}</span>
            </div>
            <div class="text-small">${capitalizeWord(name)}, ${state.toUpperCase()}</div>
            ${comments ? `<div class="my-2">${comments}</div>` : ''}
        </div>
    `
}

export const StormReports = ({ type = 'storm', stormReports }) => `
    <div class="awxjs__app__ui-panel-info__view-title">${capitalizeWord(type)} Reports</div>
        <div class="awxjs__app__ui-panel-info__obs-timestamp mb-3">Past 30 days</div>
        ${stormReports.length > 0 ? `<div class=v ui"border-bottom">
        ${stormReports.map(stormReport => StormReport(stormReport)).join('')}
    </div>` : `No ${type} reports within 25 miles`}
`;