import { baseMap } from './common/common';
import { radarButton, alertsButton, satelliteButton, stormCellsSegments, airQualitySegments } from './common/buttons';
import { alertsButtonGL, radarButtonGL, airQualitySegmentsGL, temperaturesButtonGL, droughtMonitorButtonGL } from './common/mapsGL';
import { timeline } from './common/timeline';
import { DataSources } from '../constants/dataSources';
import { DemoColors } from '../constants/colors';
import { initializeLayersPanel } from '../utils/layers';
import { DemoId, ThemeId, WeatherLayersId } from '../constants/sidebar';
import { DegreeDays } from './common/degreeDays';
import { defaultOverrides, CtoFUnits, FtoCUnits, futureOverrides } from './common/mapsGL';
import { Impacts } from './common/impacts';
import { StormReports } from './common/stormReports';
import { Forecast } from './common/forecast';
import { ForecastPeriodType } from '../constants/forecast';
// import { addLightningThreats } from './common/sources/addLightningThreats';

let _aeris;
let currentRegionData;
let regionData = {};
let lastId = '';
let mouseCoord;
let lastCoord;
const { energy, utility } = DataSources;
const currentTimestamp = Math.floor(Date.now() / 1000);

const getColor = (type, code = '') => DemoColors.energy[type][code];

const getLightningThreatColor = (data) => {
    const delta = data.period.range.maxTimestamp - currentTimestamp;

    if (delta <= 600) return '#6C71C6';

    if (delta <= 1200) return '#8585D0';

    if (delta <= 1800) return '#9C9BD9';

    if (delta <= 2400) return '#B1B0DE';

    if (delta <= 3000) return '#C9CCE7';

    return '#D1D6E7';
};

const rasterLayers = [{
    title: 'Conditions',
    buttons: [
        radarButton,
        airQualitySegments,
        {
            title: 'Temperatures',
            value: 'temperatures',
            options: {
                ...defaultOverrides
            }
        }]
}, {
    title: 'Severe',
    buttons: [
        alertsButton,
        {
            title: 'Lightning Strikes',
            value: 'lightning-strikes-15m-icons'
        },
        // {
        //     id: 'lightning',
        //     title: 'Lightning',
        //     segments: [{
        //         title: "Lightning Strikes",
        //         value: "lightning-strikes-15m-icons"
        //     },
        //     {
        //         title: "Lightning Threats",
        //         value: "lightning-threats"
        //     },]
        // },
        stormCellsSegments
    ]
}, {
    title: 'Other',
    buttons: [
        {
            id: 'fires', title: 'Fires', value: 'fires-obs'
        },
        droughtMonitorButtonGL
    ]
}];

const mapsGLConfig = {
    layers: [{
        title: 'Conditions',
        buttons: [radarButtonGL,
            airQualitySegmentsGL,
            temperaturesButtonGL,
            {
                title: 'Winds',
                value: 'wind',
                options: {
                    ...defaultOverrides,
                    paint: {
                        sample: {
                            colorscale: {
                                normalized: true,
                                stops: [0, '#fff', 1, '#fff']
                            }
                        },
                        particle: {
                            size: 1,
                            count: Math.pow(128, 2)
                        }
                    }
                },
                segments: [{
                    title: 'Particles', value: 'wind-particles'
                }, { title: 'Barbs', value: 'wind-barbs' }]
            }]
    }, {
        title: 'Severe',
        buttons: [
            alertsButtonGL,
            {
                title: 'Lightning Strikes',
                value: 'lightning-strikes'
            }, {
                id: 'stormcells',
                title: 'Storm Cells',
                value: 'stormcells'
            }]
    }, {
        title: 'Other',
        buttons: [{
            id: 'fires', title: 'Fires', value: 'fires-obs'
        }, droughtMonitorButtonGL]
    }]
};

export const EnergyConfig = ({ layers, weatherLayers, theme }) => ({
    app: {
        ...baseMap,
        mode: 'light',
        panels: {
            layers: {
                toggleable: true,
                buttons: [
                    // {
                    //     id: 'custom-geojson',
                    //     value: 'custom-geojson',
                    //     title: 'GeoJSON'
                    // },
                    {
                        id: 'power-plants',
                        title: 'Power Plants',
                        filter: true,
                        segments: [{
                            title: 'Wind', value: 'wind'
                        },
                        { title: 'Solar', value: 'solar' },
                        { title: 'Traditional Energy', value: 'fossilFuel' }]
                        // { title: 'Hydroelectric', value: 'hydroelectric' },
                        // { title: 'Nuclear', value: 'nuclear' },
                        // { title: 'Geothermal', value: 'geothermal' },
                        // { title: 'Biomass', value: 'biomass' }
                    },
                    {
                        id: 'regions',
                        title: 'Energy Regions',
                        filter: true,
                        segments: [{
                            title: 'All', value: 'allRegions'
                        },
                        { title: 'MRO', value: 'MRO' },
                        { title: 'NPCC', value: 'NPCC' },
                        { title: 'RFC', value: 'RFC' },
                        { title: 'SERC', value: 'SERC' },
                        { title: 'SPP', value: 'SPP' },
                        { title: 'TRE', value: 'TRE' },
                        { title: 'WECC', value: 'WECC' },
                        { title: 'Indeterminate', value: 'Indeterminate' }]
                    },
                    {
                        id: 'outages',
                        title: 'Transmission Lines',
                        filter: true,
                        segments: [{
                            title: 'Southern California Edison',
                            value: 'southernCaliforniaEdison'
                        }, {
                            title: 'Bonneville Power Administration',
                            value: 'bonnevillePowerAdministration'
                        }, {
                            title: 'Consumers Energy Co',
                            value: 'consumersEnergyCo'
                        }, {
                            title: 'Tennessee Valley Authority',
                            value: 'tennesseeValleyAuthority'
                        }, {
                            title: 'Oncor Electric Delivery Co',
                            value: 'oncorElectricDeliveryCo'
                        }]
                        // {
                        //     title: 'Ameren Illinois Company', value: 'americanIllinoisCompany'
                        // }
                        // { title: 'American Electric Power Co Inc', value: 'americanElectricPowerCoInc' },
                    },
                    {
                        id: 'gas-pipelines',
                        title: 'Gas Pipelines',
                        filter: true,
                        segments: [{
                            title: 'Michon Gas Co', value: 'michonGasCo'
                        }, {
                            title: 'Northern Border PL Co.', value: 'northernBorderPLCo'
                        },
                        {
                            title: 'Williston Basin Interstate PL Co.', value: 'willistonBasinInterstatePLCo'
                        }]
                        //     {
                        //     title: 'Alliance Pipeline System', value: 'alliancePipelineSystem'
                        // },
                        // { title: 'Norther Natural Gas', value: 'northernNaturalGas' },
                        // { title: 'Northern Western Energy Co.', value: 'northwesternEnergyCo' },
                        // { title: 'Viking Gas Transmission Co.', value: 'vikingGasTransmissionCo' },

                    },
                    {
                        title: 'Tropical',
                        buttons: [{
                            id: 'tropicaltest',
                            title: 'Tropical Test System',
                            value: 'tropical-cyclones',
                            options: {
                                data: {
                                    request: {
                                        endpoint: 'tropicalcyclones',
                                        parameters: {
                                            filter: 'test,geo'
                                        }
                                    }
                                }
                            }
                        }, {
                            id: 'coastalalertstest',
                            value: 'tropical-cyclones-break-points',
                            title: 'Coastal Alerts (Test)',
                            options: {
                                data: {
                                    request: {
                                        parameters: {
                                            filter: 'geo,test'
                                        }
                                    }
                                }
                            }
                        }]
                    },
                    {
                        title: 'Satellite',
                        buttons: [
                            satelliteButton
                        ]
                    },
                    ...(weatherLayers === 'raster' ? rasterLayers : [])]
            },
            info: {
                views: {
                    region: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields =
                                "timestamp,dateTimeISO, minTempC, maxTempC, minTempF, maxTempF, tempC, tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN"
                                    .split(",")
                                    .map(key => `periods.${key}`);
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').fields(forecastFields.join(',')).filter('1day')
                                    .limit(7)
                            );
                            return request;
                        },
                        views: [{
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) return;
                                let title: string = '';
                                let degrees: number = 0;

                                if (data.forecasts) {
                                    const minTempF = data.forecasts[0].periods[0].minTempF;
                                    const maxTempF = data.forecasts[0].periods[0].maxTempF;
                                    const meanTempF = (minTempF + maxTempF) / 2;

                                    if (meanTempF > 65) {
                                        title = 'Cooling';
                                        degrees = meanTempF - 65;
                                    } else if (meanTempF < 65) {
                                        title = 'Heating';
                                        degrees = 65 - meanTempF;
                                    }
                                }
                                return (`
                                        <div class="flightrule">
                                            <div class="awxjs__ui-cols align-center">
                                                <div class="awxjs__ui-expand">${title} Days</div>
                                                <div class="awxjs__ui-expand value">${degrees}</div></div>
                                            </div>
                                        </div>
                                    `);
                            }
                        }, {
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            title: 'Impacts',
                            renderer: 'hazards'
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }]
                    },
                    plant: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields =
                                "timestamp,dateTimeISO, minTempC, maxTempC, minTempF, maxTempF, tempC, tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN"
                                    .split(",")
                                    .map(key => `periods.${key}`);
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').action('closest').radius('1mi').fields(forecastFields.join(',')).filter('1hr')
                                    .limit(24)
                            );
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').fields(forecastFields.join(','))
                            );
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            request.addRequest(
                                _aeris.api().endpoint('convective/outlook').action('contains')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('lightning/summary').action('closest').radius('60mi')
                                    .limit(100)
                            );
                            request.addRequest(
                                _aeris.api().endpoint('stormreports').filter('hail').from('-30days').to('now')
                                    .limit(10)
                            );

                            return request;
                        },
                        views: [{
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) return;

                                if (data.forecasts) {
                                    return DegreeDays({ forecastsPeriods: data.forecasts[0].periods, theme });
                                }
                            }
                        }, {
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ lastCoord });
                            }
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (data?.forecasts_closest) {
                                    return Forecast({ forecasts: data.forecasts_closest, periodType: ForecastPeriodType.hourly });
                                }
                                return '';
                            }
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (data?.stormreports) {
                                    return StormReports({ type: 'hail', stormReports: data.stormreports });
                                }
                                return '';
                            }
                        }]
                    },
                    localweather: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields = 'timestamp,dateTimeISO,tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN, maxTempC, minTempC, maxTempF, minTempF'
                                .split(',')
                                .map((key) => `periods.${key}`);
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').action('closest').radius('1mi').fields(forecastFields.join(',')).filter('1hr')
                                    .limit(24)
                            );
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').fields(forecastFields.join(','))
                            );
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            request.addRequest(
                                _aeris.api().endpoint('convective/outlook').action('contains')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('stormreports').filter('hail').from('-30days').to('now')
                                    .limit(10));
                            request.addRequest(
                                _aeris.api().endpoint('lightning/summary').action('closest').radius('60mi')
                                    .limit(100)
                            );
                            return request;
                        },
                        views: [{
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) return;

                                if (data.forecasts) {
                                    return DegreeDays({ forecastsPeriods: data.forecasts[0].periods, theme });
                                }
                            }
                        },
                        {
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ lastCoord });
                            }
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (data?.forecasts_closest) {
                                    return Forecast({ forecasts: data.forecasts_closest, periodType: ForecastPeriodType.hourly });
                                }
                                return '';
                            }
                        }, {
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (data.stormreports === undefined) {
                                    return '';
                                }
                                return StormReports({ type: 'hail', stormReports: data.stormreports });
                            }
                        }]
                    }
                }
            },
            timeline: {
                toggleable: true,
                range: {
                    value: [-4, 0],
                    range: {
                        min: -48,
                        max: 0
                    },
                    marksEvery: '% 12',
                    step: 2
                }
            }
        }
    },
    events: {
        ready: (aeris, app) => {
            _aeris = aeris;
            const active = {
                powerPlants: 'wind',
                regions: 'allRegions',
                transmissionLines: 'southernCaliforniaEdison',
                gasPipelines: 'michonGasCo'
            };

            app.map.on('click', (e) => {
                lastCoord = e.data.coord;
                app.showInfoAtCoord(
                    e.data.coord,
                    'localweather',
                    `Local Weather ${currentRegionData ? `- ${currentRegionData.data.NERC} Region` : ''}`
                );
                currentRegionData = undefined;
            });

            app.addSource('power-plants', 'geojson', {
                data: {
                    url: (params) => {
                        active.powerPlants = params.filter || active.powerPlants;
                        return energy.powerPlants.sources[active.powerPlants].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    marker: (data) => ({
                        svg: {
                            shape: {
                                type: 'circle',
                                fill: {
                                    color: getColor('powerPlant', data.NAICS_DESC)
                                },
                                stroke: {
                                    color: theme === ThemeId.dark ? '#fff' : '#000',
                                    width: 1
                                }
                            }
                        },
                        size: [12, 12]
                    })
                }
            });

            // app.addSource('custom-geojson', 'geojson', {
            //     data: {
            //         url: 'https://apollo.urban10.net/aeris/js/sdk/mapsgl/assets/southernCaliforniaEdison.json',
            //         coordinate: ({ lat, lon }) => ({ lat, lon })
            //     },
            //     style: {
            //         polyline: {
            //             stroke: {
            //                 color: theme === ThemeId.dark || theme === ThemeId.satellite ? '#b0adc7' : '#727080',
            //                 width: 2
            //             }
            //         }
            //     }
            // });

            // const customGeojson = {
            //     id: 'custom-geojson',
            //     source: {
            //         type: 'geojson',
            //         data: 'https://apollo.urban10.net/aeris/js/sdk/mapsgl/assets/southernCaliforniaEdison.json',
            //     },
            //     layer: {
            //         source: 'custom-geojson',
            //         type: 'line',
            //         paint: {
            //             stroke: {
            //                 color: '#ff0000',
            //                 thickness: 5
            //             }
            //         }
            //     }
            // };

            const regions = app.addSource('regions', 'geojson', {
                data: {
                    url: (params) => {
                        active.regions = params.filter || active.regions;
                        return energy.regions.sources[active.regions].url;
                    }
                },
                style: {
                    polygon: (item) => ({
                        fill: {
                            color: getColor('region', item.NERC),
                            opacity: 0.5
                        },
                        stroke: {
                            color: 'black',
                            width: 2
                        }
                    })

                }
            });

            regions.on('data:load', (e) => {
                if (e.data?.results) {
                    regionData = (e.data.results?.features || []).reduce((prev, current) => {
                        prev[current.properties.id] = current.properties;
                        return prev;
                    }, {});
                }
            });

            const outages = app.addSource('outages', 'geojson', {
                data: {
                    url: (params) => {
                        active.transmissionLines = params.filter || active.transmissionLines;
                        return utility.transmissionLines.sources[active.transmissionLines].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    polyline: {
                        stroke: {
                            color: theme === ThemeId.dark || theme === ThemeId.satellite ? '#b0adc7' : '#727080',
                            width: 3
                        }
                    },
                    polygon: (item) => ({
                        fill: {
                            color: '#cccccc',
                            opacity: 0.4
                        },
                        stroke: {
                            color: theme === ThemeId.dark || theme === ThemeId.satellite ? '#b0adc7' : '#727080',
                            width: 2
                        }
                    }),
                    marker: (item) => {
                        if (item.type === 'crew') {
                            return {
                                className: 'assets',
                                icon: {
                                    url: '/assets/utility/wrench-tool.svg'
                                },
                                size: [25, 25]
                            }
                        }
                        return {
                            svg: {
                                shape: {
                                    type: 'circle',
                                    fill: {
                                        color: 'green'
                                    },
                                    stroke: {
                                        color: '#ffffff',
                                        width: 1
                                    }
                                }
                            },
                            size: [15, 15]
                        }
                    }
                }
            });

            outages.on('data:load', (e) => {
                if (e.data) {
                    app.map.fitBounds(utility.transmissionLines.sources[active.transmissionLines].bbox)
                }
            });

            app.addSource('gas-pipelines', 'geojson', {
                data: {
                    url: (params) => {
                        active.gasPipelines = params.filter || active.gasPipelines;
                        return utility.gasPipelines.sources[active.gasPipelines].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    polyline: {
                        stroke: {
                            color: theme === ThemeId.dark || theme === ThemeId.satellite ? '#b0adc7' : '#727080',
                            width: 2
                        }
                    }
                }
            });

            // addLightningThreats(aeris, app);

            app.map.on('shape:click', (e) => {
                if (e.data) {
                    currentRegionData = e.data || {};
                    // const source = data.awxjs_source;1
                    // if (source === 'regions') {
                    //     app.showInfo(
                    //         'region',
                    //         `${data.NERC} Region`,
                    //         data
                    //     );
                    // }
                }
            });

            app.map.on('marker:click', (e) => {
                if (e.data) {
                    const data = e.data.data || {};
                    const source = data.awxjs_source;

                    if (source === 'power-plants') {
                        lastCoord = e.data.marker.coordinate;
                        const { lat, lon } = e.data.marker.coordinate;
                        const loc = `${lat},${lon}`;
                        const name = _aeris.utils.strings.toName(data.NAME);
                        app.showInfo('plant', name).load({
                            p: loc
                        });
                    }
                }
            });

            aeris.modules().then((modules) => {
                app.modules.insertAt(5, modules.tropical.Systems);

                setTimeout(() => {
                    initializeLayersPanel(
                        aeris,
                        app,
                        {
                            demo: DemoId.energy,
                            weatherLayers
                        },
                        {
                            mapsGLConfig,
                            layersFromParams: layers
                        }
                    ).then(({ controller }) => {
                        if (weatherLayers === WeatherLayersId.mapsGL) {
                            app.on('layer:deselect', (e) => {
                                const { id } = e.data;

                                if (id === 'stormcells') {
                                    controller.removeWeatherLayer('stormcells');
                                }
                            });
                        }
                    });
                }, 250);
            });
        }
    }
});
