import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useColorModeValue,
    Text
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

interface AuthModalControl {
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void
}

const AuthModal = ({ children, authModalControl }) => {
    const { loginWithRedirect } = useAuth0();
    const { isOpen, onClose }: AuthModalControl = authModalControl;

    const handleLoginClick = () => {
        loginWithRedirect();
    };

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} isOpen={isOpen} onClose={onClose} size='lg'>
            <ModalOverlay />
            <ModalContent
                bg={useColorModeValue('gray.100', 'white')}
                mx={3}
                p={{ base: '20px', sm: '30px', md: '45px', lg: '60px' }}
            >
                <ModalHeader color='white' mt={3} fontSize='24px'>
                    Industry Use Case Demos
                </ModalHeader>
                <ModalBody py={0}>
                    <Text fontSize='14px' color='white' mb={8}>
                        Complete the form below to view sample interactive maps built with the Xweather Javascript SDK.
                    </Text>

                    {children}
                </ModalBody>

                <ModalFooter>
                    <Button
                        id='XweatherLoginButton'
                        onClick={handleLoginClick}
                        color='white'
                        size='md'
                        w='100%'
                        mt={4}
                        variant='link'
                        fontWeight='normal'
                    >
                        Or log in using your Xweather account
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AuthModal;
