import { Demo } from '../types/sidebar';
import { WeatherLayersId } from '../constants/sidebar';
import { LayersMapping } from '../constants/layersMapping';

const getDemoLayers = (demo: Demo) => LayersMapping.find((x) => x.demo === demo)!.layers;

const selectLayers = (app, layersPanel, layers: string[]) => {
    app.tokenize = false;
    layers?.forEach((id, index) => {
        if (index === layers.length - 1) {
            app.tokenize = true;
        }
        layersPanel.select(id)
    });

    refreshTimelineLayout(app.getPanel('timeline'));
};

const getInitialLayers = (demo: Demo, weatherLayers, layers: string[]) => {
    let initialLayers;

    if (layers?.length) {
        initialLayers = layers;
    } else {
        const { shared, mapsGL, raster } = getDemoLayers(demo)
        // whichever weatherLayers are selected on load have to be at the end of the array as the app.tokenize logic doesn't fire if the layer cannot be selected
        const distinctLayersOrdered = weatherLayers === WeatherLayersId.mapsGL ? [...raster, ...mapsGL] : [...mapsGL, ...raster];
        initialLayers = [...shared, ...distinctLayersOrdered];
    }
    return initialLayers;
};

const refreshTimelineLayout = (timelinePanel) => {
    let toggledElement: HTMLElement | null = document.querySelector('.awxjs__app__ui-panel-timeline');

    if (timelinePanel) {
        setTimeout(() => timelinePanel.trigger('show'));
    }

    if (toggledElement?.style) {
        setTimeout(() => toggledElement!.style.opacity = '1', 200);
    }
};

export const initializeLayersPanel = (
    aeris,
    app,
    sidebarState: { demo: Demo, weatherLayers: string },
    layersConfig: { mapsGLConfig?: any, layersFromParams: string[] }) => new Promise<any>((resolve, reject) => {
        const { demo, weatherLayers } = sidebarState;
        const { mapsGLConfig, layersFromParams } = layersConfig;
        const layersPanel = app.getPanel('layers');
        const initialLayers = getInitialLayers(demo, weatherLayers, layersFromParams);

        if (layersPanel) {
            layersPanel.open();

            if (weatherLayers === WeatherLayersId.mapsGL) {
                aeris.mapsgl(app, mapsGLConfig).then(({ controller, mapsgl }) => {
                    controller.addLegendControl(app.$el, { width: 300 });

                    console.log('MapsGL loaded:', controller, mapsgl);
                    selectLayers(app, app.panels.layers, initialLayers);

                    controller.addDataInspectorControl({ event: 'move' });

                    resolve({ controller });
                })
            } else {
                selectLayers(app, layersPanel, initialLayers);
                resolve({});
            }
        }
    });
