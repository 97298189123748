import * as jose from 'jose';

interface JWTClaim {
    config?: string,
    mapType?: string,
    mapTheme?: string,
    zoom?: number,
    lat?: number,
    lon?: number
}

export const getToken = (state: JWTClaim) => createJWTToken(state).then((data) => data);

export const createJWTToken = async (payload: any) => {
    const secret = new TextEncoder().encode(
        'A01hFxlZmVfidmROyboW'
    );
    const alg = 'HS256';

    const jwt = await new jose.SignJWT({ 'urn:example:claim': payload })
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setIssuer('urn:example:issuer')
        .setAudience('urn:example:audience')
        .setExpirationTime('2h')
        .sign(secret);

    return jwt;
};

export const parseJWTToken = (token) => {
    if (!token) return {};
    const claims = jose.decodeJwt(token);

    return claims;
};
