import { useEffect } from 'react';
import {
    Box,
    Flex
} from '@chakra-ui/react';
import { Demo } from '../../types/sidebar';
import { DemoId } from '../../constants/sidebar';
import { SectionTitle } from './SectionTitle';

export const DemoContainer = ({ children, demo }: { children: React.ReactNode, demo: Demo }) => {

    useEffect(() => {
        const content: HTMLElement = document.querySelector('.content')!;
        const demosRequiringTopScroll: Demo[] = [DemoId.severe, DemoId.aviation, DemoId.energy];
        const demosRequiringBottomScroll: Demo[] = [DemoId.telecommunications, DemoId.routing, DemoId.maritime];

        if (demosRequiringTopScroll.includes(demo)) {
            content.scrollTop = 0;
        }
        if (demo === DemoId.facilities) {
            content.scrollTop = content.offsetHeight / 2;
        }
        if (demosRequiringBottomScroll.includes(demo)) {
            content.scrollTop = content.scrollHeight;
        }
        applyContainerShadow();
    }, []);

    const applyContainerShadow = () => {
        const content: HTMLElement = document.querySelector('.content')!;
        const wrapper: HTMLElement = document.querySelector('.wrapper')!;
        const shadowTop: HTMLElement = document.querySelector('.shadow--top')!;
        const shadowBottom: HTMLElement = document.querySelector('.shadow--bottom')!;
        const contentScrollHeight: number = content.scrollHeight > wrapper.offsetHeight ? content.scrollHeight - wrapper.offsetHeight : wrapper.offsetHeight - content.scrollHeight;
        const currentScroll: number = content.scrollTop / (contentScrollHeight);
        shadowTop.style.opacity = String(currentScroll);
        shadowBottom.style.opacity = String(1 - currentScroll);
        if (content.scrollHeight > content.clientHeight) {
            shadowTop.style.opacity = String(currentScroll);
            shadowBottom.style.opacity = String(1 - currentScroll);
        } else {
            shadowTop.style.opacity = '0';
            shadowBottom.style.opacity = '0';
        }
    };

    return (
        <>
            <Box
                px={{ base: 2, md: 3, xxl: 5 }}
                pt={{ base: 2, md: 3, xxl: 5 }}>
                <SectionTitle>Demo</SectionTitle>
            </Box>

            <Flex className='demo-list-container wrapper' h='auto' direction='column' position='relative' overflowX='hidden' flex={1}>

                <Box className='content'
                    overflowY='auto'
                    overflowX='hidden'
                    h='full'
                    px={{ base: 2, md: 3, xxl: 5 }}
                    pt={{ base: 2 }}
                    onScroll={() => applyContainerShadow()}>
                    <div className="shadow shadow--top"></div>
                    <div className="shadow shadow--bottom"></div>
                    {children}
                </Box>
                <Box mx={{ base: 2, md: 3, xxl: 5 }}
                    pb={{ base: 2, md: 3, xxl: 4 }}
                    borderBottom='1px'
                    borderColor='gray.200' />
            </Flex>
        </>
    )
};
