import {
    radarButton,
    satelliteButton,
    alertsButton,
    temperaturesButton,
    windsButton,
    dewPointButton,
    feelsLikeButton,
    snowDepthButton,
    convectiveButton
} from './buttons';

export const commonControls = {
    settings: [{
        type: 'opacity'
    }]
};

export const baseMap = {
    map: {
        map: {
            zoomControl: false,
        },
        zoom: 4
    },
    timeline: {
        alwaysShowFuture: true
    },
    refresh: 120,
    mode: 'light'
};

export const rasterLayers = [
    radarButton,
    satelliteButton,
    alertsButton,
    temperaturesButton,
    windsButton,
    dewPointButton,
    feelsLikeButton,
    snowDepthButton,
    convectiveButton
];
