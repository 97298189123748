import { format } from "date-fns";
import { ForecastPeriodType } from "../../constants/forecast";
import { WbloxIconsUrl } from "../../constants/urls";

const MarkupType = {
    temp: 'temp',
    time: 'time',
    wind: 'wind'
} as const;

type ForecastPeriodType = (typeof ForecastPeriodType)[keyof typeof ForecastPeriodType];
type MarkupType = (typeof MarkupType)[keyof typeof MarkupType];

interface Forecast {
    forecasts: any,
    periodType?: ForecastPeriodType
}

const getMarkup = (forecast) => {
    const date: Date = new Date(forecast.dateTimeISO);
    const formattedDay: string = format(date, 'EEE');
    const formattedDate: string = format(date, 'MM/dd');
    const formattedTime: string = format(date, 'hh:mm a');

    return {
        [ForecastPeriodType.hourly]: {
            temp: `<div class='font-size-large'>${forecast.tempF}<span class="text-small units">°F</span></div>`,
            time: `<div class='forecast-date-container__time'>${formattedTime}</div>`,
            wind: `<span>Winds</span> ${forecast.windSpeedMPH} mph`
        },
        [ForecastPeriodType.weekly]: {
            temp: `<div>${forecast.minTempF}<span class="mx-1">/</span><span>${forecast.maxTempF}<span class="text-small units">°F</span></span></div>`,
            time: `<span class="forecast-date-container__day">${formattedDay}</span>
            <span class="forecast-date-container__date">${formattedDate}</span>`,
            wind: `<span>Winds</span> ${forecast.windSpeedMinMPH}-${forecast.windSpeedMaxMPH} mph`
        }
    };
};

const ForecastRow = (forecast, period: ForecastPeriodType) => {
    const markup = getMarkup(forecast);

    return `
        <div class='forecast-row py-2'>
            <div class='forecast-row__column ${period === ForecastPeriodType.hourly ? 'w-30' : 'w-15'} align-items-start'>
                ${markup[period][MarkupType.time]}
            </div>
            <div class='forecast-row__column ${period === ForecastPeriodType.hourly ? 'w-15' : 'w-30'} align-items-center'>
                ${markup[period][MarkupType.temp]}
            </div>
            <div class='forecast-row__column w-15 align-items-center'>
            <img class="awxjs__app__ui-panel-info__forecast-icon" src="${WbloxIconsUrl}${forecast.icon}">

            </div>
            <div class='forecast-row__column flex-column w-40 align-items-start'>
            <div class="awxjs__app__ui-panel-info__forecast-wx">${forecast.weatherPrimary}</div>
                <div class="awxjs__app__ui-panel-info__forecast-detail">
                    ${markup[period][MarkupType.wind]}
                </div>
            </div>
        </div>
    `;
}

export const Forecast = ({ forecasts, periodType = ForecastPeriodType.weekly }: Forecast) => {
    const forecastPeriods = forecasts ? forecasts['0'].periods : [];
    const title = {
        [ForecastPeriodType.hourly]: '24 Hour Forecast',
        [ForecastPeriodType.weekly]: '7 Day Forecast'
    }[periodType];

    return `
        <div class="awxjs__app__ui-panel-info__view-title">${title}</div>
        ${forecastPeriods.map(forecastPeriod => ForecastRow(forecastPeriod, periodType)).join('')}
    `;
};