const KEYS = {
    aeris: {
        id: process.env.AERIS_ID,
        secret: process.env.AERIS_SECRET
    },
    mapbox: process.env.MAPBOX_KEY,
    google: process.env.GOOGLE_KEY,
    auth0: {
        domain: process.env.AUTH0_DOMAIN,
        clientId: process.env.AUTH0_CLIENT_ID
    }
};

export default KEYS;
