export const timeline = {
    timeline: {
        toggleable: true,
        alwaysShowFuture: true,
        range: {
            value: [-4, 0],
            range: {
                min: -48,
                max: 0
            },
            marks: {
                '-48': '-48',
                '-36': '-36',
                '-24': '-24',
                '-12': '-12',
                '0': 'Now',
                '12': '+12',
                '24': '+24'
            }
        }
    }
};
