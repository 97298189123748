import { Text } from '@chakra-ui/react';

export const SectionTitle = ({ children }: { children: string }) => (
    <Text
        color='white'
        fontSize="11px"
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing="wide"
        mb={{ base: 1, xl: 2 }}
    >
        {children}
    </Text>);
