import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { FORM_COOKIE_NAME } from '../constants/cookie';

const useCookie = () => {
    const [hasCookie, setHasCookie] = useState<boolean>(Cookies.get(FORM_COOKIE_NAME) === '1');
    const urlParams = new URLSearchParams(document.location.search);

    const setCookie = (domain: string) => {
        Cookies.set(FORM_COOKIE_NAME, '1', {
            domain: process.env.APP_ENV === 'prod' ? domain : window.location.hostname,
            expires: 365
        });
    };

    const setCookies = () => {
        setCookie('.aerisweather.com');
        setCookie('.xweather.com');
        setHasCookie(true);
    };

    useEffect(() => {
        if (hasCookie || urlParams.get('allow') === '1') {
            setCookies();
        }

    }, [hasCookie]);

    return { hasCookie, setCookies };
};

export default useCookie;
