/* eslint-disable object-curly-newline */
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    Box,
    Flex,
    useColorModeValue,
    Link,
    BoxProps,
    Button,
    Spacer,
    Select,
    Divider
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { DemoId } from '../../constants/sidebar';
import { SidebarUrls, XWeatherUrl } from '../../constants/urls';
import {
    AutomotiveIcon,
    AviationIcon,
    EnergyIcon,
    FacilityIcon,
    TelecommunicationsIcon,
    SevereIcon,
    MaritimeIcon
} from '../../assets/icons/sidebar/demo';
import { MapWizardIcon, DocumentationIcon, PlansPricingIcon, OtherExamplesIcon } from '../../assets/icons/sidebar/site';
import { ExpandIcon } from '../../assets/icons/sidebar/expand';
import { Logo } from '../../assets/icons/sidebar/xweatherLogo';
import { SectionTitle } from './SectionTitle';
import { MapThemeControl } from './MapThemeControl';
import { MapStrategyControl } from './MapStrategyControl';
import { DemoContainer } from './DemoContainer';
import ActiveDemoIcon from './ActiveDemoIcon';
import CopyUrlButton from './CopyUrlButton';
import { SidebarState, StrategySelectOption } from '../../types/sidebar';

interface Dropdown {
    value: string;
    label: string;
}

interface NavDemoButtonProps {
    id: string,
    name: string;
    Icon: (props) => JSX.Element;
}

interface NavLinkProps {
    id: string,
    name: string;
    url: string
    Icon: (props) => JSX.Element;
}

interface SidebarProps extends BoxProps {
    drawerControl: {
        isOpen: boolean,
        onOpen: () => void,
        onClose: () => void
    }
    sidebarState: SidebarState,
    onSidebarStateChange: ({ control, value }) => void
}

const demoLinkItems: Array<NavDemoButtonProps> = [{
    id: DemoId.severe, name: 'Outdoor Rec / Severe', Icon: SevereIcon
},
{ id: DemoId.aviation, name: 'Aviation', Icon: AviationIcon },
{ id: DemoId.energy, name: 'Energy & Utility', Icon: EnergyIcon },
{ id: DemoId.facilities, name: 'Facility Mgmt & Insurance', Icon: FacilityIcon },
{ id: DemoId.routing, name: 'Transportation & Logistics', Icon: AutomotiveIcon },
{ id: DemoId.maritime, name: 'Maritime', Icon: MaritimeIcon },
{ id: DemoId.telecommunications, name: 'Telecommunications', Icon: TelecommunicationsIcon }];

const siteLinkItems: Array<NavLinkProps> = [{
    id: 'mapWizard', name: 'Map Wizard', url: SidebarUrls.mapWizard, Icon: MapWizardIcon
},
{ id: 'documentation', name: 'Documentation', url: SidebarUrls.documentation, Icon: DocumentationIcon },
{ id: 'otherExamples', name: 'Other Examples', url: SidebarUrls.otherExamples, Icon: OtherExamplesIcon }];

const mapStrategies: Array<StrategySelectOption> = [{
    value: 'mapbox', label: 'Mapbox'
}, {
    value: 'maplibre', label: 'Map Libre'
},
{ value: 'google', label: 'Google Maps' },
{ value: 'leaflet', label: 'Leaflet' }];

const weatherLayers: Array<Dropdown> = [{
    value: 'raster', label: 'Raster'
},
{ value: 'mapsGL', label: 'MapsGL' }];

const removeOverlay = () => {
    setTimeout(() => {
        let overlay: HTMLElement | null = document.querySelector('.chakra-modal__content-container');
        if (overlay) {
            overlay.style.width = '0px';
        }
    });
};

const Sidebar = ({
    drawerControl,
    sidebarState,
    onSidebarStateChange
}: SidebarProps) => {
    const { isOpen, onOpen, onClose } = drawerControl;
    removeOverlay();

    return (
        <>
            <IconButton
                aria-label='Expand button'
                className='button-holder'
                onClick={onOpen}
                border='1px'
                borderRadius='0 3px 3px 0'
                borderColor='gray.300'
                bg='gray.300'
                _hover={{
                    borderColor: 'gray.500'
                }}
                padding='px'
                color='white'
                fontSize='40px'
                icon={<ExpandIcon fill='white' />}>
            </IconButton>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
            >
                <DrawerContent>
                    <DrawerBody p={0}>
                        <Box
                            bg={useColorModeValue('gray.100', 'white')}
                            borderBottom='4px'
                            borderBottomColor='brand.main'
                            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
                            w='100%'
                            pos='fixed'
                            h='full'>
                            <Flex h='full' direction='column'>
                                <Flex
                                    p={{ base: 2, md: 3, xxl: 4 }}
                                    alignItems='center'
                                    justifyContent='space-between'
                                    bg='brand.main'
                                    color='white'>
                                    <Link className='logo-link' href={XWeatherUrl} >
                                        <Logo
                                            _hover={{ filter: 'drop-shadow(rgb(0 0 0 / 0.3) -1px 1px 2px )' }}
                                        />
                                    </Link>
                                    <DrawerCloseButton position='static' />
                                </Flex>
                                <DemoContainer demo={sidebarState.demo}>
                                    {demoLinkItems.map((link) => (
                                        <NavDemoButton
                                            key={link.name}
                                            isActive={link.id === sidebarState.demo}
                                            Icon={link.Icon}
                                            fontWeight='bold'
                                            whiteSpace='nowrap'
                                            onClick={() => onSidebarStateChange({
                                                control: 'demo',
                                                value: link.id
                                            })}
                                        >
                                            {link.name}
                                        </NavDemoButton>
                                    ))}
                                </DemoContainer>
                                <Flex h='auto' overflowY='auto'>
                                    <Box p={{ base: 2, md: 3, xxl: 5 }} overflowY='auto' w='full' mt='auto'>
                                        <Box py={{ base: 2, md: 3, xxl: 4 }}>
                                            <SectionTitle>Map Theme</SectionTitle>
                                            <MapThemeControl
                                                strategy={sidebarState.strategy}
                                                theme={sidebarState.theme}
                                                onClick={onSidebarStateChange} />
                                        </Box>
                                        <Box py={{ base: 2, md: 3, xxl: 4 }}>
                                            <SectionTitle>Map Library</SectionTitle>
                                            <MapStrategyControl
                                                strategySelected={sidebarState.strategy}
                                                strategies={mapStrategies}
                                                onClick={onSidebarStateChange} />
                                        </Box>
                                        <Box py={{ base: 2, md: 3, xxl: 4 }}>
                                            <SectionTitle>Weather Layers</SectionTitle>
                                            <Select
                                                border='0'
                                                size='sm'
                                                fontWeight='bold'
                                                onChange={(e) => onSidebarStateChange({
                                                    control: 'weatherLayers',
                                                    value: e?.target?.value
                                                })}
                                                defaultValue={sidebarState.weatherLayers}>
                                                {weatherLayers.map((weatherLayer) => (
                                                    <option
                                                        key={weatherLayer.value}
                                                        value={weatherLayer.value}>
                                                        {weatherLayer.label}
                                                    </option>))}
                                            </Select>
                                        </Box>
                                        <Flex py={{ base: 2, md: 3, xxl: 4 }}>
                                            <Button
                                                border='1px'
                                                borderColor='brand.main'
                                                borderRadius='sm'
                                                size='sm'
                                                bg='brand.main'
                                                _hover={{
                                                    bg: '#0a94d4'
                                                }}
                                                color='white'
                                                leftIcon={<PlansPricingIcon boxSize='1rem' fill='white' />}
                                                fontWeight='normal'
                                                fontSize='13px'
                                                w='50%'
                                                justifyContent="flex-start"
                                                onClick={() => window.open(SidebarUrls.pricing, '_blank')}
                                            >
                                                Plans + Pricing
                                            </Button>
                                            <Spacer p='2' />
                                            <CopyUrlButton />
                                        </Flex>
                                        <Box
                                            borderTop='1px'
                                            borderColor='gray.200'
                                            pt={{ base: 2, md: 3, xxl: 5 }}
                                            mt={{ base: 2, md: 3, xxl: 4 }}>
                                            {siteLinkItems.map((link) => (
                                                <NavLink
                                                    key={link.name}
                                                    Icon={link.Icon}
                                                    url={link.url}
                                                    fontSize='12px'
                                                    size='sm'>
                                                    {link.name}
                                                </NavLink>
                                            ))}
                                        </Box>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

const NavIcon = ({ Icon, isActive = false, ...rest }) => <Flex
    align='center'
    alignItems='center'
    w='28px'
    h='40px'
    zIndex={1}
    {...rest}>
    {isActive
        ? <ActiveDemoIcon><Icon boxSize={4} fill='gray.100' /></ActiveDemoIcon>
        : <Icon boxSize={4} fill='white' />}
</Flex>;

const NavDemoButton = ({ children, Icon, isActive, ...rest }) => <Button
    w='full'
    borderRadius='sm'
    px='2'
    justifyContent="flex-start"
    color='white'
    _hover={{
        bg: isActive ? 'gray.100' : 'gray.300'
    }}
    {...rest}
>
    <NavIcon Icon={Icon} isActive={isActive} justifyContent='center' mr={3} />
    {children}
    {isActive && <motion.div
        className='w-100'
        animate={{ x: -5 }}
        initial={{ x: 28 }}
        transition={{ duration: 0.3 }}
    >
        <Box
            position='relative'
            left={{ base: '21px', md: '25px', xxl: '32px' }} h='6px' w='28px' bg='brand.main' ml='auto'
        />
    </motion.div>}
</Button>;

const NavLink = ({ children, Icon, url, ...rest }) => <Link
    href={url}
    style={{ textDecoration: 'none' }}
    _focus={{ boxShadow: 'none' }}
    target='_blank'>
    <Flex
        align='center'
        pr='2'
        borderRadius='sm'
        role='group'
        cursor='pointer'
        color='white'
        height='32px'
        _hover={{
            bg: 'gray.300'
        }}
        {...rest}>
        <NavIcon Icon={Icon} />
        {children}
    </Flex>
</Link>;

export default Sidebar;
