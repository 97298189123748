import {
    alertsButton,
    radarButton,
    satelliteButton,
    heatIndexButton,
    snowDepthButton,
    stormCellsSegments,
    airQualitySegments
} from './common/buttons';
import {
    lightningStyle,
    defaultOverrides,
    futureOverrides,
    lightningInfo,
    FtoCUnits,
    CtoFUnits,
    opacityOptions,
    alertsButtonGL,
    radarButtonGL,
    airQualitySegmentsGL,
    snowDepthButtonGL
} from './common/mapsGL';
import { initializeLayersPanel } from '../utils/layers';
import { DemoId, WeatherLayersId } from '../constants/sidebar';
import { Impacts } from './common/impacts';
import { DataSources } from '../constants/dataSources';
import { StormReports } from './common/stormReports';
import { Forecast } from './common/forecast';
// import { addLightningThreats } from './common/sources/addLightningThreats';

let _aeris;
let lastCoord;
const outdoor = DataSources.outdoor;

const rasterLayers = [{
    title: 'Severe',
    buttons: [
        alertsButton,
        {
            id: 'stormreports',
            title: 'Storm Reports',
            value: 'stormreports'
        }, {
            title: 'Lightning Strikes',
            value: 'lightning-strikes'
        },
        stormCellsSegments
    ]
}, {
    title: 'Conditions',
    buttons: [
        airQualitySegments,
        radarButton,
        snowDepthButton,
        heatIndexButton
    ]
}, {
    title: 'Other',
    buttons: [{
        title: 'Fires', value: 'fires'
    }]
}];

const mapsGLConfig = {
    layers: [{
        title: 'Severe',
        buttons: [alertsButtonGL,
            {
                id: 'stormreports',
                title: 'Storm Reports',
                value: 'stormreports'
            },
            {
                id: 'lightning',
                title: 'Lightning',
                value: 'lightning-all'
            }, 
            {
                id: 'lightningStrikesCustom',
                title: 'Lightning Strikes: Custom',
                value: 'lightning-strikes',
                options: {...lightningStyle}
                
            }, {
                id: 'stormcells',
                title: 'Storm Cells',
                value: 'stormcells',
                options: {
                    data: {
                        evaluator: {
                            title: 'Storm Cells',
                            fn: (value) => {
                                if (value.length === 1) {
                                    return 'storm approaching!';
                                    // return title(value[0].traits.type) + ' storm approaching!';
                                } else if (value.length > 1) {
                                    return value.length + ' storms approaching!';
                                }
                                return 'No storms';
                            }
                        }
                    }
                }
            }]
    }, {
        title: 'Conditions',
        buttons: [
            airQualitySegmentsGL,
            radarButtonGL,
            snowDepthButtonGL,
            {
                id: 'heat-index',
                title: 'Heat Index',
                value: 'heat-index',
                options: opacityOptions,
                settings: [{
                    key: 'color.scale',
                    overrides: {
                        scales: ['theme1.temps', 'theme2.temps', 'theme3.temps', 'general']
                    }
                }, {
                    key: 'color.interval',
                    overrides: {
                        intervals: [0, 1, 2, 5, 10],
                        converter: (v) => FtoCUnits(v),
                        formatter: (v) => `${v}&deg;F`
                    }
                }, 'data.interpolation', 'data.quality']
            }, {
                title: 'Winds',
                value: 'wind',
                options: {
                    ...defaultOverrides,
                    paint: {
                        sample: {
                            colorscale: {
                                normalized: true,
                                stops: [
                                    0, '#fff',
                                    1, '#fff'
                                ]
                            }
                        },
                        particle: {
                            size: 1,
                            count: 64000
                        }
                    }
                },
                segments: [{
                    title: 'Particles', value: 'wind-particles'
                }, { title: 'Barbs', value: 'wind-barbs' }]
            }]
    }, {
        title: 'Other',
        buttons: [{
            id: 'fires', title: 'Fires', value: 'fires-obs'
        }]
    }]
};

export const SevereConfig = ({ weatherLayers, layers }) => ({
    app: {
        map: {
            map: {
                zoomControl: false
            },
            refresh: 120,
            zoom: 4
        },
        mode: 'light',
        panels: {
            layers: {
                toggleable: true,
                buttons: [
                    {
                        id: 'stadiums',
                        title: 'Stadiums',
                        filter: true,
                        segments: [{
                            title: 'Baseball',
                            value: 'baseball'
                        }, {
                            title: 'Football',
                            value: 'football'
                        },
                        {
                            title: 'Soccer',
                            value: 'soccer'
                        }]
                    },
                    {
                        value: 'schools',
                        title: 'Schools'
                    },
                    {
                        value: 'parks',
                        title: 'Parks'
                    },
                    {
                        title: 'Satellite',
                        buttons: [
                            satelliteButton
                        ]
                    },
                    {
                        id: 'tropical-cyclones',
                        title: 'Tropical System (Test)',
                        value: 'tropicaltest',
                        options: {
                            data: {
                                request: {
                                    endpoint: 'tropicalcyclones',
                                    parameters: {
                                        filter: 'test,geo'
                                    }
                                }
                            }
                        }
                    }, {
                        id: 'coastalalertstest',
                        value: 'tropical-cyclones-break-points',
                        title: 'Coastal Alerts (Test)',
                        options: {
                            data: {
                                request: {
                                    parameters: {
                                        multiselect: true,

                                        filter: 'geo,test'
                                    }
                                }
                            }
                        }
                    },
                    ...(weatherLayers === 'raster' ? rasterLayers : []),
                ]
            },
            info: {
                views: {
                    localweather: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields = 'timestamp,dateTimeISO,tempF,tempC,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN, maxTempC, minTempC, maxTempF, minTempF'
                                .split(',')
                                .map((key) => `periods.${key}`);
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').fields(forecastFields.join(','))
                            );
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            request.addRequest(
                                _aeris.api().endpoint('convective/outlook').action('contains')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('stormreports').from('-30days').to('now')
                                    .limit(10)
                            );
                            request.addRequest(
                                _aeris.api().endpoint('lightning/summary').action('closest').radius('60mi')
                                    .limit(100)
                            );
                            return request;
                        },
                        views: [
                            {
                                title: 'Active Alerts',
                                renderer: 'alerts'
                            }, {
                                title: 'Active Threats',
                                renderer: 'threats'
                            }, {
                                data: (data) => {
                                    if (_aeris.utils.isEmpty(data)) return null;
                                    return data;
                                },
                                renderer: (data) => {
                                    if (!data) {
                                        return '';
                                    }
                                    return Impacts({ lastCoord });
                                }
                            }, {
                                title: 'Current Conditions',
                                renderer: 'obs'
                            }, {
                                data: (data) => {
                                    if (_aeris.utils.isEmpty(data)) return null;
                                    return data;
                                },
                                renderer: (data) => {
                                    if (data?.forecasts) {
                                        return Forecast({ forecasts: data.forecasts });
                                    }
                                    return '';
                                }
                            }, {
                                data: (data) => {
                                    if (_aeris.utils.isEmpty(data)) return null;
                                    return data;
                                },
                                renderer: (data) => {
                                    if (data?.stormreports) {
                                        return StormReports({ stormReports: data.stormreports });
                                    }
                                    return '';
                                }
                            }]
                    }
                }
            },
            timeline: {
                toggleable: true,
                range: {
                    value: [-4, 0],
                    range: {
                        min: -48,
                        max: 48
                    },
                    marksEvery: '% 12',
                    step: 4
                }
            }
        }
    },
    events: {
        ready: (aeris, app) => {
            _aeris = aeris;
            let stadiumsActive = 'baseball';

            app.map.on('click', (e) => {
                lastCoord = e.data.coord;
                app.showInfoAtCoord(e.data.coord, 'localweather', 'Local Weather');
            });

            app.addSource('stadiums', 'vector', {
                data: {
                    url: (params) => {
                        stadiumsActive = params.filter || stadiumsActive;
                        return outdoor.stadiums.sources[stadiumsActive].url;
                    },
                    coordinate: ({ lat, lon }) => ({ lat, lon })
                },
                style: {
                    marker: () => {
                        const icon = outdoor.stadiums.sources[stadiumsActive].icon;
                        return {
                            className: 'stadiums',
                            icon: {
                                url: icon.url
                            },
                            size: [24, 24]
                        };
                    }
                }
            });

            const parksRequest = aeris
                .api()
                .endpoint('places')
                .action('search')
                .query('country:us')
                .filter('park')
                .mindist(100)
                .limit(50000)
                .format('geojson');
            parksRequest.lod(50);

            app.addSource('parks', 'geojson', {
                data: {
                    service: parksRequest,
                    formatter: (data) => data
                },
                style: {
                    marker: () => ({
                        className: 'parks',
                        icon: {
                            url: outdoor.parks.sources.icon.url
                        },
                        size: [24, 24]
                    })
                }
            });

            const schoolsRequest = aeris
                .api()
                .endpoint('places')
                .action('search')
                .query('country:us')
                .filter('school')
                .mindist(75)
                .limit(500)
                .format('geojson');
            schoolsRequest.lod(50);

            app.addSource('schools', 'geojson', {
                data: {
                    service: schoolsRequest,
                    formatter: (data) => data
                },
                style: {
                    marker: () => ({
                        className: 'parks',
                        icon: {
                            url: outdoor.schools.sources.icon.url
                        },
                        size: [24, 24]
                    })
                }
            });

            // aeris.modules().then((modules) => {
            //     app.modules.insertAt(5, modules.tropical.Systems, {
            //         controls: {
            //             segments: [{
            //                 title: 'Active Cyclones',
            //                 value: 'active,geo',
            //                 selected: true
            //             }, {
            //                 title: 'All Cyclones',
            //                 value: 'geo',
            //                 options: {
            //                     data: {
            //                         request: {
            //                             parameters: {
            //                                 from: '1/1/2020',
            //                                 to: '12/30/2023',
            //                                 limit: 100,
            //                                 save: false
            //                             }
            //                         }
            //                     }
            //                 }
            //             }]
            //         }
            //     });

            aeris.modules().then((modules) => {
                app.modules.insertAt(4, modules.groups.Tropical);

                setTimeout(() => {
                    initializeLayersPanel(
                        aeris,
                        app,
                        { demo: DemoId.severe, weatherLayers },
                        { mapsGLConfig, layersFromParams: layers }
                    ).then(({ controller }) => {
                        app.on('layer:deselect', (e) => {
                            const { id } = e.data;

                            if (id === 'stormcells' && weatherLayers === WeatherLayersId.mapsGL) {
                                controller.removeWeatherLayer('stormcells');
                            }
                        });
                    });
                }, 250);
            });
            // });
        }
    }
});
